import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { MANAGE } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/facebook',
  component: LazyLoadWithCircularProgress(() => import('./containers/base')),
  pageTitle: 'Facebook',
  exactly: true,
  pageType: MANAGE,
  flowType: STORE
}]

export default Routes
