import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Wrapper from '../components/wrapper'

import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import get from 'lodash/get'
import has from 'lodash/has'

import { GLOBAL_VARIANTS, DISCOUNTS, ORDERS } from 'core/authentication-editor/feature-flags'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import withLocalizeText from '../../../core/withLocalizeText'

import { getSetupStatus } from '../../../modules/setup/store/actions'

const mapStateToProps = (state) => {
  return {
    activePage: state.router.activePage,
    isAuthenticated: state.auth.isAuthenticated,
    getLimitationsResponse: state.auth.getLimitationsResponse,
    pathToLeaveFocusView: state.router.pathToLeaveFocusView,
    hideNav: state.router.hideNav,
    showGlobalVariants: get(state.auth.features, GLOBAL_VARIANTS) === true,
    showDiscounts: get(state.auth.features, DISCOUNTS) === true,
    showOrders: get(state.auth.features, ORDERS) === true,
    showInsights: get(state.auth.features, ORDERS) === true,
    getStoreStatusRetrieving: state.setup.getStoreStatusRetrieving,
    status: state.setup.getStoreStatusResponse
  }
}

const mapDispatch = (dispatch) => {
  return {
    getSetupStatus: bindActionCreators(getSetupStatus, dispatch)
  }
}
/**
 * Core that Wraps the application, will render the hamburger menu
 *
 * @class AppWrapperContainer
 * @extends {Component}
 * @param drawerIsOpen - drawer is open boolean
 * @param notificationDrawerIsOpen - drawer  of notifications is open boolean
 * @param breadCrumb - array of the current breadCrumb
 * @param menuItems - main menuItems that should be displayed
 */
class AppWrapperContainer extends Component {
  static propTypes = {
    activePage: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    getStoreStatusRetrieving: PropTypes.bool,
    state: PropTypes.object,
    menuItems: PropTypes.object,
    localize: PropTypes.func
  }

  static contextTypes = {
    localize: PropTypes.func
  }

  render () {
    const {
      menuItems
    } = this.props

    // set document title
    if (this.props.activePage && has(this.props.activePage, 'documentTitle')) {
      if (isFunction(this.props.activePage.documentTitle)) {
        window.document.title = this.props.activePage.documentTitle(this.props.localize) + ' | ' + getRuntimeConfig()._brandDisplayName
      } else if (isString(this.props.activePage.documentTitle)) {
        window.document.title = this.props.activePage.documentTitle + ' | ' + getRuntimeConfig()._brandDisplayName
      }
    } else {
      window.document.title = getRuntimeConfig()._brandDisplayName
    }

    return <Wrapper
      menuItems={menuItems}
      {...this.props}
    />
  }
}

const instance = connect(mapStateToProps, mapDispatch)(withLocalizeText(AppWrapperContainer))

export default instance
