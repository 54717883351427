import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/features',
  component: LazyLoadWithCircularProgress(() => import('./containers/base')),
  pageTitle: 'Advanced Features',
  exactly: true,
  pageType: EDITOR,
  flowType: STORE
}]

export default Routes
