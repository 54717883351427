import AuthenticationHelper from './authentication-helper'
import AuthenticationContainer from './containers'
import ReducerFuncs from './store'

export default AuthenticationHelper

const Reducers = (reducerObj) => { reducerObj.auth = ReducerFuncs }

export {
  AuthenticationContainer,
  Reducers
}
