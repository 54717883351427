import { Localizations } from 'localize'

Localizations.append({
  authentication: {
    containers: {
      index: {
        error: 'Error during authentication'
      }
    }
  }
})
