import * as constants from './constants'

import { fetchAsJson } from '@eig-builder/core-utils/helpers/fetch-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'

import AuthenticationHelper from './../authentication-helper'

/**
 * Check if a user is signed in on the server
 *
 * @returns Promise
 */
export const checkAuth = () => {
  return AuthenticationHelper.checkAuth({ key: constants.GET_CURRENT_USER })
}

export const getLimitations = () => {
  return fetchAsJson(
    api('/v1.0/limitations/all'),
    {
      // fakeSuccess: MockLimitation,
      key: constants.GET_LIMITATIONS,
      convertToCamelCase: false
    })
}

export const unlockLimitationHackOrderTakesToLong = (limitationKey) => {
  return {
    key: constants.UPDATE_LIMITATION,
    limitationKey: limitationKey
  }
}
