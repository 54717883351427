import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/order/detail/:id?',
  component: LazyLoadWithCircularProgress(() => import('./containers/order-detail')),
  pathToLeaveFocusView: '/orders',
  pageType: EDITOR,
  flowType: STORE
},
{
  path: '/order/abandoned/:id?',
  component: LazyLoadWithCircularProgress(() => import('./components/abandoned-cart-details')),
  pathToLeaveFocusView: '/orders',
  pageType: EDITOR,
  flowType: STORE
},
{
  path: '/orders/:tab?',
  component: LazyLoadWithCircularProgress(() => import('./containers/base')),
  exactly: true,
  pageType: EDITOR,
  flowType: STORE
}
]

export default Routes
