export const GET_STORE_SETTINGS = 'GET_STORE_SETTINGS'
export const GET_SHIPPING_SETTINGS = 'GET_SHIPPING_SETTINGS'
export const GET_LOCALIZATION_COUNTRIES = 'GET_LOCALIZATION_COUNTRIES'
export const GET_LOCALIZATION_COUNTRY_STATES = 'GET_LOCALIZATION_COUNTRY_STATES'
export const GET_LOCALIZATION_CURRENCIES = 'GET_LOCALIZATION_CURRENCIES'
export const GET_LOCALIZATION_COUNTRIES_FULFILLED = 'GET_LOCALIZATION_COUNTRIES_FULFILLED'
export const GET_LOCALIZATION_CURRENCIES_FULFILLED = 'GET_LOCALIZATION_CURRENCIES_FULFILLED'

export const GET_BUSINESS_FORM = 'GET_BUSINESS_FORM'
export const SAVE_BUSINESS_FORM = 'SAVE_BUSINESS_FORM'

export const GET_METRICS_FORM = 'GET_METRICS_FORM'
export const SAVE_METRICS_FORM = 'SAVE_METRICS_FORM'

export const GET_SHIPPING_FORM = 'GET_SHIPPING_FORM'
export const SAVE_SHIPPING_FORM = 'SAVE_SHIPPING_FORM'

export const POST_SHIPPING_RATE_TYPE = 'POST_SHIPPING_RATE_TYPE'

export const GET_CHARGE_PER_PRODUCT_SHIPPING_FORM = 'GET_CHARGE_PER_PRODUCT_SHIPPING_FORM'
export const SAVE_CHARGE_PER_PRODUCT_SHIPPING_FORM = 'SAVE_CHARGE_PER_PRODUCT_SHIPPING_FORM'

export const GET_LEGAL_FORM = 'GET_LEGAL_FORM'
export const SAVE_LEGAL_FORM = 'SAVE_LEGAL_FORM'

export const GET_TAX_FORM = 'GET_TAX_FORM'
export const SAVE_TAX_FORM = 'SAVE_TAX_FORM'
export const GET_TAX_COUNTRY_MANUAL = 'GET_TAX_COUNTRY_MANUAL'
export const GET_TAX_COUNTRY_FORM = 'GET_TAX_COUNTRY_FORM'
export const SAVE_TAX_COUNTRY_FORM = 'SAVE_TAX_COUNTRY_FORM'
export const DELETE_TAX_COUNTRY_FORM = 'DELETE_TAX_COUNTRY_FORM'
export const GET_TAX_REGIONS_FORM = 'GET_TAX_REGIONS_FORM'
export const SAVE_TAX_REGIONS_FORM = 'SAVE_TAX_REGIONS_FORM'
export const GET_TAX_RULE_TYPES = 'GET_TAX_RULE_TYPES'

export const GET_NOTIFICATION_FORM = 'GET_NOTIFICATION_FORM'
export const GET_NOTIFICATION_PREVIEW = 'GET_NOTIFICATION_PREVIEW'
export const SAVE_NOTIFICATION_FORM = 'SAVE_NOTIFICATION_FORM'

export const GET_SHIPPING_ADDRESS_FORM = 'GET_SHIPPING_ADDRESS_FORM'
export const SAVE_SHIPPING_ADDRESS_FORM = 'SAVE_SHIPPING_ADDRESS_FORM'

export const GET_CORREIOS_FORM = 'GET_CORREIOS_FORM'
export const SAVE_CORREIOS_FORM = 'SAVE_CORREIOS_FORM'

export const DISABLE_CORREIOS = 'DISABLE_CORREIOS'
export const DISABLE_CORREIOS_FULFILLED = 'DISABLE_CORREIOS_FULFILLED'

export const RESET_GET_TAX_COUNTRY_FORM = 'RESET_GET_TAX_COUNTRY_FORM'

export const SAVE_GO_SHIPPO_FORM = 'SAVE_GO_SHIPPO_FORM'
export const GET_GO_SHIPPO_FORM = 'GET_GO_SHIPPO_FORM'
export const RESET_GET_GOSHIPPO_FORM = 'RESET_GET_GOSHIPPO_FORM'

export const GET_TRACKING_FORM = 'GET_TRACKING_FORM'
export const SAVE_TRACKING_FORM = 'SAVE_TRACKING_FORM'

export const GET_SINGLE_FORM = 'GET_SINGLE_FORM'
export const SAVE_SINGLE_FORM = 'SAVE_SINGLE_FORM'

export const GET_PAYMENT_OPTIONS_OVERVIEW = 'GET_PAYMENT_OPTIONS_OVERVIEW'

export const POST_SETTINGS_ONBOARDING_COMPLETE = 'POST_SETTINGS_ONBOARDING_COMPLETE'

export const CHANGE_DEFAULT_SIZE = 'CHANGE_DEFAULT_SIZE'

export const GET_PAYMENT_OPTIONS = 'GET_PAYMENT_OPTIONS'

export const GET_ONBOARDING_FORM = 'GET_ONBOARDING_FORM'
export const SAVE_ONBOARDING_FORM = 'SAVE_ONBOARDING_FORM'
export const RESET_SAVE_ONBOARDING_FORM = 'SAVE_ONBOARDING_FORM_RESET'

export const GET_PICKUP_FORM = 'GET_PICKUP_FORM'
export const SAVE_PICKUP_FORM = 'SAVE_PICKUP_FORM'
