import { useState, useEffect } from 'react'
import Store from '@eig-builder/core-utils/store'
import get from 'lodash/get'

function shallowCompare (newObj, prevObj) {
  for (const key in newObj) {
    if (newObj[key] !== prevObj[key]) return true
  }
  return false
}

function getLimitationStateFromReduxState (state) {
  const error = get(state, 'auth.getLimitationsError')
  return {
    data: get(state, 'auth.getLimitationsResponse'),
    isLoading: get(state, 'auth.getLimitationsRetrieving'),
    isError: !!error,
    error: error
  }
}

const useLimitation = () => {
  const store = Store()

  const [limitationState, setLimitationState] = useState(getLimitationStateFromReduxState(store.getState()))

  useEffect(
    () => {
      store.subscribe((a, b) => {
        const obj = getLimitationStateFromReduxState(store.getState())
        if (shallowCompare(obj, limitationState)) {
          setLimitationState(obj)
        }
      })
    },
    []
  )

  return [limitationState]
}

export default useLimitation
