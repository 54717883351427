import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  getStoreStatusRetrieving: true,
  getStoreStatusError: null,
  getStoreStatusResponse: null
}

const reducer = (state = initialState, action) => {
  return ReducerHelper.listenToFetchActions(state, action, constants.GET_STORE_STATUS) || state
}

export default reducer
