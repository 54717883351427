import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import { countryIncludesStates } from '../helpers/order-helpers'

import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'

const initialState = {
  getAllOrdersRetrieving: false,
  getAllOrdersResponse: [],

  getOrderFormRetrieving: false,
  getOrderFormResponse: null,

  paymentIncludesStates: false,
  customerIncludesStates: false,
  shippingIncludesStates: false,

  createShippingPackageForLineItemsRetrieving: false,
  createShippingPackageForLineItemsResponse: null,

  sendConfirmedShippingPackageDetailsRetrieving: false,
  sendConfirmedShippingPackageDetailsResponse: null,

  sendShippingPackageDimensionsRetrieving: false,
  sendShippingPackageDimensionsResponse: null,

  modifyOrderLineShippingStatusRetrieving: false,
  modifyOrderLineShippingStatusResponse: null,

  buyShippoLabelForOrderRetrieving: false,
  buyShippoLabelForOrderResponse: null,

  getExistingShippoLabelForOrderLineRetrieving: false,
  getExistingShippoLabelForOrderLineResponse: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORDER_FORM_FULFILLED: {
      const newState = cloneDeep(state)

      newState.getOrderFormRetrieving = false
      newState.getOrderFormResponse = action.body

      const { getOrderFormResponse } = newState

      if (!isEmpty(getOrderFormResponse)) {
        newState.paymentIncludesStates = has(getOrderFormResponse, 'payment_form.values.country') && countryIncludesStates(getOrderFormResponse.payment_form.values.country)
        newState.customerIncludesStates = has(getOrderFormResponse, 'customer_form.values.country') && countryIncludesStates(getOrderFormResponse.customer_form.values.country)
        newState.shippingIncludesStates = has(getOrderFormResponse, 'shipping_form.values.country') && countryIncludesStates(getOrderFormResponse.shipping_form.values.country)
      }

      return newState
    }
    case constants.RESET_GET_ORDER_FORM: {
      return {
        ...state,
        getOrderFormRetrieving: false,
        getOrderFormResponse: null
      }
    }
    case constants.RESET_CONTACT_FORM: {
      return {
        ...state,
        getContactFormRetrieving: false,
        getContactFormResponse: null,
        getContactFormError: null
      }
    }

    case constants.RESET_SHIPPING_PACKAGE_WIZARD: {
      const newState = { ...state }
      ReducerHelper.resetSubmitStates(newState, constants.CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS)
      ReducerHelper.resetSubmitStates(newState, constants.SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS)
      ReducerHelper.resetSubmitStates(newState, constants.SEND_SHIPPING_PACKAGE_DIMENSIONS)
      return newState
    }

    case constants.RESET_CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS: {
      const newState = { ...state }
      ReducerHelper.resetSubmitStates(newState, constants.CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS)
      return newState
    }

    case constants.RESET_SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS: {
      const newState = { ...state }
      ReducerHelper.resetSubmitStates(newState, constants.SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS)
      return newState
    }

    case constants.RESET_SEND_SHIPPING_PACKAGE_DIMENSIONS: {
      const newState = { ...state }
      ReducerHelper.resetSubmitStates(newState, constants.SEND_SHIPPING_PACKAGE_DIMENSIONS)
      return newState
    }
  }

  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_ORDERS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ORDER_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CONTACT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.POST_CONTACT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_SHIPPING_LABEL) ||
    ReducerHelper.listenToFetchActions(state, action, constants.CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SEND_SHIPPING_PACKAGE_DIMENSIONS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.MODIFY_ORDER_LINE_SHIPPING_STATUS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.RESEND_ORDER_LINE_FULFILLMENT_EMAIL) ||
    ReducerHelper.listenToFetchActions(state, action, constants.BUY_SHIPPO_LABEL_FOR_ORDER) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_EXISTING_SHIPPO_LABEL_FOR_ORDER_LINE) ||
    state
  )
}

export default reducer
