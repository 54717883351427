
import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/setup-guide',
  component: LazyLoadWithCircularProgress(() => import('./components/setup-guide-home')),
  pageTitle: 'Setup Guide',
  exactly: true,
  pageType: EDITOR,
  flowType: STORE
}]

export default Routes
