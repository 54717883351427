import { LazyLoadWithCentredLoader } from '../../core/lazyLoad'
import { PRODUCT_ONBOARDING_BASE_PATH } from '../onboarding/store-first/routes'

import { CREATION, EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Redirector = LazyLoadWithCentredLoader(() => import('./components/home'))

const Routes = [
  {
    path: '/intro',
    component: LazyLoadWithCentredLoader(() => import('./pages/intro')),
    pageTitle: 'Create your store',
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: PRODUCT_ONBOARDING_BASE_PATH,
    pageTitle: 'StoreFirst Onboarding',
    component: LazyLoadWithCentredLoader(() => import('../onboarding/store-first/containers/product-onboarding')),
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/onboarding/part2',
    component: LazyLoadWithCentredLoader(() => import('./pages/flow-2')),
    pageTitle: 'Setup Address',
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/onboarding/part3',
    component: LazyLoadWithCentredLoader(() => import('./pages/flow-3')),
    pageTitle: 'Setup Address',
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/onboarding/store-front',
    component: LazyLoadWithCentredLoader(() => import('./containers/store-front')),
    pageTitle: 'Setup Address',
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  },
  {
    path: '/home',
    component: Redirector,
    hideNav: true,
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/setup',
    component: Redirector,
    hideNav: true,
    pageType: CREATION,
    flowType: STORE
  }
]

export default Routes
