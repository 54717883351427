import React from 'react'
import Bootstrapper from '@eig-builder/module-bootstrapper'
import PropTypes from 'prop-types'

import config from '../config'
import ErrorBoundry from './components/ErrorBoundry'

// Routes
import { Routes as NotFoundRouter } from 'modules/notfound'
import { Routes as SessionExpired } from 'modules/session-expired'
import { Routes as SetupRouter, Reducers as SetupReducer } from 'modules/setup'
import { Routes as OrdersRouter, Reducers as OrdersReducer } from 'modules/orders'
import { Routes as ProductsRouter, Reducers as ProductsReducer } from 'modules/products'
import { Routes as DiscountsRouter, Reducers as DiscountsReducer } from 'modules/discounts'
import { Routes as FacebookRouter, Reducers as FacebookReducer } from 'modules/facebook'
import { Routes as FeaturesRouter, Reducers as FeaturesReducer } from 'modules/features'
import { Routes as InsightsRouter, Reducers as InsightsReducer } from 'modules/insights'
import { Routes as SettingsRouter, Reducers as SettingsReducer } from 'modules/settings'
import { Routes as PaymentRouter, Reducers as PaymentReducer } from 'modules/payment-options'
import { Routes as VariantsRouter, Reducers as VariantsReducer } from 'modules/global-variants'
import { Routes as OnboardingRouter } from 'modules/onboarding2'
import { Routes as SetupGuideRouter } from 'modules/setup-guide'

// Authentication
import { Reducers as AuthenticationReducers } from '../../../core/authentication-editor'

import { PropertyEditorContext } from '@eig-builder/module-property-editor'
import Select from '@eig-builder/module-property-editor-field-select'
import Autocomplete from '@eig-builder/module-property-editor-field-autocomplete'
import Colorpicker from '@eig-builder/module-property-editor-field-colorpicker'
import Colorsinput from '@eig-builder/module-property-editor-field-colorsinput'
import Currency from '@eig-builder/module-property-editor-field-currency'
import DateField from '@eig-builder/module-property-editor-field-date'
import Html from '@eig-builder/module-property-editor-field-html'
import Multiselect from '@eig-builder/module-property-editor-field-multiselect'
import Percentage from '@eig-builder/module-property-editor-field-percentage'
import Tags from '@eig-builder/module-property-editor-field-tags'
import ImagesUploader from '@eig-builder/module-property-editor-field-imagesuploader'
import SignedUrlFileUpload from './../../../core/property-editor-fields/signedurlfileupload'
import AutoSelect from '../../../core/property-editor-fields/auto-select'

// Notification Bar
import { NotificationBarWrapperWithContext } from '@eig-builder/module-notification-bar-with-context'
import * as GlobalStore from '@eig-builder/core-utils/store'

// Additional Reducers.
import { Reducers as ImportReducer } from 'core/import'

import { getLoginUrl } from '@eig-builder/core-utils/helpers/url-helper'

// Modals
import { Reducers as ModalsReducers } from '@eig-builder/module-modals'
import { ModalContextProviderWrapper } from '@eig-builder/module-modals/withModalContext'
// Wrappers
import Wrapper from './components/wrapper-with-store-editor-menu-items'
import InjectGlance from '../../../core/injectGlanceForCtct'
import MfeProvider from '@eig-builder/module-addons-mfe'

import '@eig-builder/core-utils/base-style/main.scss'

const propertyEditorContextValue = {
  useApiGateway: true,
  additionalFieldTypes: [
    Select,
    Autocomplete,
    AutoSelect,
    Colorpicker,
    Colorsinput,
    Currency,
    DateField,
    Html,
    Multiselect,
    Percentage,
    Tags,
    ImagesUploader,
    SignedUrlFileUpload
  ]
}

const routes = [
  OnboardingRouter,
  NotFoundRouter,
  SessionExpired,
  SetupRouter,
  OrdersRouter,
  ProductsRouter,
  DiscountsRouter,
  FacebookRouter,
  InsightsRouter,
  SettingsRouter,
  PaymentRouter,
  VariantsRouter,
  FeaturesRouter,
  SetupGuideRouter
]

const BaseRouterWrapper = ({ children }) => (
  <NotificationBarWrapperWithContext>
    <PropertyEditorContext.Provider value={propertyEditorContextValue}>
      <ModalContextProviderWrapper>
        <MfeProvider type='IMAGE_LIBRARY' version='image-library-mfe@1' insideSingleSpa={false}>
          <InjectGlance app='APP_STORE_EDITOR'>
            {children}
          </InjectGlance>
        </MfeProvider>
      </ModalContextProviderWrapper>
    </PropertyEditorContext.Provider>
  </NotificationBarWrapperWithContext>
)

BaseRouterWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

const localStore = GlobalStore.create(config, [
  AuthenticationReducers,
  ModalsReducers,
  SetupReducer,
  OrdersReducer,
  ProductsReducer,
  DiscountsReducer,
  FacebookReducer,
  InsightsReducer,
  SettingsReducer,
  PaymentReducer,
  VariantsReducer,
  ImportReducer,
  FeaturesReducer
])

localStore.dispatch({
  type: 'AVAILABLE_ROUTES',
  payload: routes
})

export default () => {
  return (
    <ErrorBoundry>
      <Bootstrapper
        routes={routes}
        BaseRouterWrapper={BaseRouterWrapper}
        appWrapper={Wrapper}
        loginUrl={getLoginUrl()}
        store={localStore}
        defaultRoute='/home'
      />
    </ErrorBoundry>
  )
}
