import * as constants from './constants'
import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import SchemaSubmitHelper from '@eig-builder/module-property-editor/helpers/schema-submit'

const initialState = {
  getPaymentOptionsOverviewRetrieving: false,
  getPaymentOptionsRetrieving: false,
  getStripePaymentStatus: false,
  getStripePaymentStatusRetrieving: false
}

const reducer = (state = initialState, action) => {
  const listenState = ReducerHelper.listenToFetchActions(state, action, constants.GET_PAYMENT_OPTIONS_OVERVIEW) ||
                      ReducerHelper.listenToFetchActions(state, action, constants.GET_PAYMENT_OPTIONS) ||
                      ReducerHelper.listenToFetchActions(state, action, constants.GET_STRIPE_PAYMENT_STATUS) ||
                      SchemaSubmitHelper.listenToSubmit(state, action, constants.POST_PAYMENT_OPTIONS, 'postPaymentOptions') ||
                      state

  if (listenState) {
    return listenState
  }

  switch (action.type) {
    case constants.RESET_FORM_OPTIONS: {
      const newState = { ...state }
      SchemaSubmitHelper.resetSubmitStates(newState, 'getPaymentOptions')
      return newState
    }
  }
}

export default reducer
