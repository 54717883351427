/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
// babel Polyfill
import 'core-js/shim'
import 'regenerator-runtime/runtime'
// Element Polyfill
import './polyfill/element'

import { init as initDatadog } from '@eig-builder/core-utils/helpers/apm/datadog-helper'
import { setGlobalModifyHeader } from '@eig-builder/core-utils/helpers/fetch-helper'
import { setAgGridLicense } from '@eig-builder/module-smp-grid'

import {
  setGlobalStoreToken,
  getQueryParametersFromUrl,
  setGlobalInstanceJwt,
  setStoreBrandFromQs,
  setGlobalStoreLocale
} from '@eig-builder/core-utils/helpers/url-helper'
import { SHOPPABLE_RETURN_URL } from '../../constants'
import get from 'lodash/get'

import { render } from 'react-dom'
import React from 'react'
import Root from 'app'

import './sass/main.scss'

import 'intl'
import 'intl/locale-data/jsonp/en.js'
/* eslint-enable import/first */
if (!window.EventSource) {
  import(/* webpackChunkName: "event-source-polyfill" */ 'event-source-polyfill')
}

initDatadog()

// Set instanceJwt if its avaible in the url
const params = getQueryParametersFromUrl(window.location.href, true)
const storeToken = get(params, 'token')
const storeLocale = get(params, 'locale')
const instanceJwt = get(params, 'instancejwt')
const storeBrandFromQs = get(params, 'brand')
const shoppableReturnUrl = get(params, 'returnurl')

if (shoppableReturnUrl) {
  window.sessionStorage.setItem(SHOPPABLE_RETURN_URL, shoppableReturnUrl)
}

setGlobalInstanceJwt(instanceJwt)

setGlobalModifyHeader((header) => {
  header.Authorization = `Bearer ${storeToken}`
})

setGlobalStoreToken(storeToken)

setStoreBrandFromQs(storeBrandFromQs)

setGlobalStoreLocale(storeLocale)

setAgGridLicense()

render(
  <Root />,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept('app', () => {
    const NewRoot = require('app').default

    render(
      <NewRoot />,
      document.getElementById('root')
    )
  })
}
