import orderBy from 'lodash/orderBy'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'

export function getActiveCategoryIdAndExpandedTree (allCategories, expandedCategories) {
  const loopThroughCategories = category => {
    const reconstructCategory = cloneDeep(category)
    reconstructCategory.children = []
    if (expandedCategories.length > 1 && expandedCategories.includes(reconstructCategory.id)) {
      reconstructCategory.expanded = true
    }
    if (category.children.length) {
      reconstructCategory.children = category.children.map(childCat => {
        const child = loopThroughCategories(childCat)
        return child
      })
    }
    return reconstructCategory
  }
  const categories = allCategories.filter(f => f.id !== 0 && f.id !== -1)
  const result = categories.map(c => loopThroughCategories(c))
  return result
}

export function getNestedChildren (items, parentId) {
  const out = []
  for (const i in items) {
    if (items[i].parentId === parentId) {
      const children = getNestedChildren(items, items[i].id)
      items[i].children = children
      out.push(items[i])
    }
  }
  return out
}

export function setCorrectOrderNested (items) {
  items = orderBy(items, ['order', 'name'])
  for (const i in items) {
    if (items[i].children && items[i].children.length > 0) {
      items[i].children = setCorrectOrderNested(items[i].children)
    }
  }
  return items
}

export function findMaxOrder (allCategories, parentId) {
  let order = 0
  // if no parent, just check the main items in response array
  if (!parentId) {
    order = findMaxOrderInList(allCategories)
  } else {
    // find parent
    const arrParent = findParentList(allCategories, parentId)
    // find hoghest order within parebnt list
    if (arrParent && arrParent.length > 0) {
      order = findMaxOrderInList(arrParent)
    }
  }
  return order + 1
}

export function findMaxOrderInList (arrCategories) {
  let order = 0
  for (let i = 0; i < arrCategories.length; i++) {
    if (arrCategories[i] && arrCategories[i].order && arrCategories[i].order > order) {
      order = arrCategories[i].order
    }
  }
  return order
}

export function findParentList (arrCategories, parentId) {
  let arrParent = []
  for (let i = 0; i < arrCategories.length; i++) {
    if (arrCategories[i] && arrCategories[i].id && arrCategories[i].id === parentId) {
      arrParent = arrCategories[i].children
      break
    } else if (arrCategories[i].children) {
      arrParent = findParentList(arrCategories[i].children, parentId)
      if (arrParent && arrParent.length > 0) {
        break
      }
    }
  }
  return arrParent
}

export function findParentLvL (arrCategories, parentId, lvl) {
  for (let i = 0; i < arrCategories.length; i++) {
    if (arrCategories[i] && arrCategories[i].id && arrCategories[i].id === parentId) {
      return lvl
    } else if (arrCategories[i].children) {
      const result = findParentLvL(arrCategories[i].children, parentId, lvl + 1)
      if (result) {
        return result
      }
    }
  }
}

export function findCategory (arrCategories, categoryId) {
  for (let i = 0; i < arrCategories.length; i++) {
    if (arrCategories[i] && arrCategories[i].id && arrCategories[i].id === categoryId) {
      return arrCategories[i]
    } else if (arrCategories[i].children) {
      const category = findCategory(arrCategories[i].children, categoryId)
      if (category) {
        return category
      }
    }
  }
}

export function findCategoryByTitle (arrCategories, title) {
  let node

  const searchCats = cats => {
    for (let i = 0; i < cats.length; i++) {
      const category = cats[i]
      if (category && category.title && category.title === title) {
        node = category
      } else if (category.children && category.children.length) {
        searchCats(category.children)
      }
    }
  }

  searchCats(arrCategories)
  return node
}

export function findAllChildIds (category) {
  let result = []
  if (!category || !category.children) {
    return result
  }

  category.children.forEach(item => {
    result.push(item.id)
    if (item.children) {
      result = result.concat(findAllChildIds(item))
    }
  })
  return result
}

export function removeCategory (arrCategories, categoryId) {
  for (let i = 0; i < arrCategories.length; i++) {
    if (arrCategories[i] && arrCategories[i].id && arrCategories[i].id === categoryId) {
      arrCategories.splice(i, 1)
    } else if (arrCategories[i].children) {
      removeCategory(arrCategories[i].children, categoryId)
    }
  }
}

export function addCategoryToList (arrCategories, categoryItem, boolFound) {
  for (let i = 0; i < arrCategories.length; i++) {
    if (boolFound) {
      break
    }
    if (arrCategories[i].parentId === categoryItem.parentId || (!arrCategories[i].parentId && !categoryItem.parentId)) {
      if (categoryItem.order <= arrCategories[i].order || i + 1 === arrCategories.length) {
        arrCategories.splice(categoryItem.order <= arrCategories[i].order ? i : i + 1, 0, categoryItem)
        boolFound = true
        break
      }
    } else if (arrCategories[i].children) {
      if (arrCategories[i].children.length === 0 && arrCategories[i].id === categoryItem.parentId) {
        arrCategories[i].children.push(categoryItem)
        boolFound = true
        break
      } else {
        addCategoryToList(arrCategories[i].children, categoryItem)
      }
    }
  }
}

export function updateCategoryNodeTreeCount (nodeTree, catId, parentNode, categoryList) {
  forEach(nodeTree, (item, index) => {
    item.needTobeUpdated = false
    if (item.id === catId) {
      item.productsInCategory += 1
      if (parentNode) {
        parentNode.needTobeUpdated = true
      }
      return false
    } else if (item.children) {
      item.children = updateCategoryNodeTreeCount(item.children, catId, item, categoryList)
      if (item.needTobeUpdated) {
        const checkCat = find(categoryList, productCatItem => item.id === productCatItem.categoryId)
        if (!checkCat) {
          item.productsInCategory += 1
          if (parentNode) {
            parentNode.needTobeUpdated = true
          }
        }
      }
    }
  })
  return nodeTree
}
