import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { DrawerContext, Navigation, PageContent } from '@eig-builder/module-navigation'

const HamburgerMenu = props => {
  const history = useHistory()
  const items = props.menuItems

  return (
    <DrawerContext.Provider
      value={{
        menuItems: items
      }}
      >
      <Navigation history={history}>
        <Navigation.Drawer defaultOpen={false} showBackDrop toggleDrawerOnClick />
        <Navigation.DetailBar>
          <Navigation.LeftAlign>
            <Navigation.HamburgerMenu />
          </Navigation.LeftAlign>
          <Navigation.CenterAlign />
        </Navigation.DetailBar>
        <PageContent>{props.children}</PageContent>
      </Navigation>
    </DrawerContext.Provider>
  )
}

HamburgerMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired
}

export default HamburgerMenu
