import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/discount/detail/:id?',
  component: LazyLoadWithCircularProgress(() => import('./containers/discount-detail')),
  pathToLeaveFocusView: '/discounts',
  pageType: EDITOR,
  flowType: STORE
},
{
  path: '/discounts',
  component: LazyLoadWithCircularProgress(() => import('./containers/base')),
  pageType: EDITOR,
  flowType: STORE
}]

export default Routes
