import {
  Localizations
} from 'localize'

Localizations.append({
  appStoreEditor: {
    client: {
      components: {
        wrapper: {
          home: 'Home',
          orders: 'Orders',
          products: 'Products',
          insights: 'Insights',
          discount: 'Discounts',
          facebookStore: 'Facebook Store',
          settings: 'Settings',
          advancedFeature: 'Advanced Features',
          globalVariants: 'Global Variants'
        }
      }
    }
  }
})
