export const RESET_GET_ORDER_FORM = 'RESET_GET_ORDER_FORM'

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ORDER_FORM = 'GET_ORDER_FORM'

export const GET_CONTACT_FORM = 'GET_CONTACT_FORM'
export const POST_CONTACT_FORM = 'POST_CONTACT_FORM'
export const RESET_CONTACT_FORM = 'RESET_CONTACT_FORM'

export const RESET_SHIPPING_PACKAGE_WIZARD = 'RESET_SHIPPING_PACKAGE_WIZARD'
export const RESET_CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS = 'RESET_CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS'
export const RESET_SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS = 'RESET_SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS'
export const RESET_SEND_SHIPPING_PACKAGE_DIMENSIONS = 'RESET_SEND_SHIPPING_PACKAGE_DIMENSIONS'

export const SAVE_SHIPPING_LABEL = 'SAVE_SHIPPING_LABEL'

export const GET_ORDER_FORM_FULFILLED = 'GET_ORDER_FORM_FULFILLED'

export const CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS = 'CREATE_SHIPPING_PACKAGE_FOR_LINE_ITEMS'

export const SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS = 'SEND_CONFIRMED_SHIPPING_PACKAGE_DETAILS'

export const SEND_SHIPPING_PACKAGE_DIMENSIONS = 'SEND_SHIPPING_PACKAGE_DIMENSIONS'

export const MODIFY_ORDER_LINE_SHIPPING_STATUS = 'MODIFY_ORDER_LINE_SHIPPING_STATUS'

export const RESEND_ORDER_LINE_FULFILLMENT_EMAIL = 'RESEND_ORDER_LINE_FULFILLMENT_EMAIL'

export const BUY_SHIPPO_LABEL_FOR_ORDER = 'BUY_SHIPPO_LABEL_FOR_ORDER'

export const GET_EXISTING_SHIPPO_LABEL_FOR_ORDER_LINE = 'GET_EXISTING_SHIPPO_LABEL_FOR_ORDER_LINE'
