import { getQueryParametersFromUrl } from '@eig-builder/core-utils/helpers/url-helper'
import get from 'lodash/get'
import { SHOPPABLE_RETURN_URL } from '../services/constants'

const params = getQueryParametersFromUrl(window.location.href, true)
const shoppableReturnUrl = get(params, 'returnurl')

const setShoppableReturnUrltoSessionStorage = () => {
  if (shoppableReturnUrl) {
    window.sessionStorage.setItem(SHOPPABLE_RETURN_URL, shoppableReturnUrl)
  }
}

setShoppableReturnUrltoSessionStorage()

const globalShoppableReturnUrl = () => {
  if (shoppableReturnUrl) {
    return shoppableReturnUrl
  } else {
    return window.sessionStorage.getItem(SHOPPABLE_RETURN_URL)
  }
}

const cleanShoppableReturnUrlSessionStorage = () => {
  if (!shoppableReturnUrl) {
    window.sessionStorage.removeItem(SHOPPABLE_RETURN_URL)
  }
}

export {
  globalShoppableReturnUrl,
  setShoppableReturnUrltoSessionStorage,
  cleanShoppableReturnUrlSessionStorage
}
