import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Paper from '@eig-builder/core-ui/Paper'
import MenuItem from '@eig-builder/core-ui/MenuItem'
import MenuList from '@material-ui/core/MenuList'
// import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded'
import { withStyles } from '@eig-builder/core-ui/styles'
import { Route, Link } from 'react-router-dom'

import { DrawerContext } from './../contexts/drawer-context'
import { getDataProperty, DataElementLocations, DataElementTypes } from '@eig-builder/core-utils/helpers/tagging-helper'

import './lang'
import Text from 'localize'

import brandColors from 'brand_variables_js'

const styles = theme => ({
  root: {
    color: theme.palette.white.mainText,
    width: 300,
    paddingLeft: 20,
    paddingTop: 20,
    position: 'absolute',
    zIndex: 1000
  },
  header: {
    paddingLeft: 10,
    paddingTop: 20
  },
  headerSubTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 200
  },
  headerTitle: {
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 480,
    marginTop: -5
  },
  paper: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 2,
    padding: 15,
    minHeight: 400,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start'
  },
  mainMenu: {
    color: theme.palette.white.mainText,
    marginTop: 10
  },
  link: {
    textDecoration: 'none'
  },
  mainMenuItemRoot: {
    color: theme.palette.white.mainText
  },
  mainMenuItemSelected: {
    color: brandColors.navbarTopDarkTextColor,
    backgroundColor: brandColors.navbarInactiveColor + '!important' // not sure needs some more research why i needed important
  },
  backToEditor: {
    color: theme.palette.white.mainText,
    marginTop: 'auto'
  },
  backToEditorIcon: {
    backgroundColor: brandColors.navbarInactiveColor,
    borderRadius: '1rem',
    color: brandColors.navbarTopDarkBackgroundColor,
    fontSize: '1.2rem',
    padding: 2,
    marginRight: 10
  }
})

class FloatingLeftNavBar extends Component {
  static propTypes = {
    menuItems: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired
  }

  render () {
    const items = this.props.menuItems
    const classes = this.props.classes

    return (
      <DrawerContext.Consumer>
        {({ open }) => {
        const style = {}
        if (open === false) {
          style.position = 'hidden'
        }

        return (
          <div className={classes.root} styles={style}>
            <Paper className={classes.paper}>
              <header className={classes.header}>
                <div className={classes.headerSubTitle}>
                  <Text message='store.core.floatingLeftNavBar.headerSubTitle' />
                </div>
                <div className={classes.headerTitle}>
                  <Text message='store.core.floatingLeftNavBar.headerTitle' />
                </div>
              </header>
              <MenuList className={classes.mainMenu}>
                {items.map((item, index) => {
                  return <Route
                    key={index} path={item.link} exact={false} children={({ match }) => (
                      <Link
                        {...getDataProperty({
                        dataElementLocation: DataElementLocations.HEADER,
                        dataElementLabel: item.tag,
                        dataElementId: `left-nav-${item.tag}`,
                        dataElementType: DataElementTypes.MENUITEM
                      })}
                        to={item.link}
                        className={classes.link}
                      >
                        <MenuItem
                          classes={{ root: ((item.tag === 'setup') && item.class + ' ') + classes.mainMenuItemRoot, selected: ((item.tag === 'setup') && classes.mainMenuItemSelected) }}
                          selected={match !== null}
                        >
                          {item.title}
                        </MenuItem>
                      </Link>
                    )}
                  />
                })}
              </MenuList>
              {/* <MenuItem className={classes.backToEditor}><ArrowBackRounded className={classes.backToEditorIcon} />Back to Editor</MenuItem> */}
            </Paper>
          </div>
        )
      }}
      </DrawerContext.Consumer>
    )
  }
}

export default withStyles(styles)(FloatingLeftNavBar)
