import React from 'react'
import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

import './lang'
import Text from 'localize'

const Routes = [
  {
    path: '/settings/onboarding',
    component: LazyLoadWithCircularProgress(() => import('./containers/settings-onboarding')),
    pathToLeaveFocusView: '/settings',
    hideNav: true,
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/settings/taxes/manual/:id?',
    component: LazyLoadWithCircularProgress(() => import('./containers/tax-rate')),
    pageTitle: <Text message='modules.settings.routes.taxDetails.pageTitle' />,
    pathToLeaveFocusView: '/settings',
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/settings/edit/:id?',
    component: LazyLoadWithCircularProgress(() => import('./containers/single-edit-complex')),
    pageTitle: <Text message='modules.settings.routes.editSettings.pageTitle' />,
    pathToLeaveFocusView: '/settings',
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/settings/:tab?',
    component: LazyLoadWithCircularProgress(() => import('./containers/single-page')),
    pageTitle: 'Settings',
    exactly: true,
    pageType: EDITOR,
    flowType: STORE
  }
]

export default Routes
