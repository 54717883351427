import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  getAllGlobalVariantsRetrieving: false,
  getAllGlobalVariantsResponse: [],

  getGlobalVariantFormRetrieving: false,
  getGlobalVariantFormResponse: null,

  submitGlobalVariantFormResponse: null,
  submitGlobalVariantFormRetrieving: false,

  deleteGlobalVariantRetrieving: false,
  deleteGlobalVariantResponse: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.RESET_GET_GLOBAL_VARIANT_FORM: {
      return {
        ...state,
        getGlobalVariantFormRetrieving: false,
        getGlobalVariantFormResponse: null
      }
    }
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_GLOBAL_VARIANTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_GLOBAL_VARIANT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_GLOBAL_VARIANT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_GLOBAL_VARIANT) ||
    state
  )
}

export default reducer
