import Routes from './routes'
import ReducerFuncs from './store'

const Reducers = (reducerObj) => {
  reducerObj.settings = ReducerFuncs
  reducerObj.business = ReducerFuncs
  reducerObj.metrics = ReducerFuncs
  reducerObj.shipping = ReducerFuncs
  reducerObj.notifications = ReducerFuncs
  reducerObj.taxes = ReducerFuncs
  reducerObj.tracking = ReducerFuncs
}

export {
  Routes,
  Reducers
}
