export const RESET_GET_PRODUCT_FORM = 'RESET_GET_PRODUCT_FORM'
export const RESET_GET_CATEGORY_FORM = 'RESET_GET_CATEGORY_FORM'
export const RESET_PRODUCT_VARIANTS = 'RESET_PRODUCT_VARIANTS'
export const RESET_STOCK = 'RESET_STOCK'
export const RESET_STOCK_FORM = 'RESET_STOCK_FORM'

export const UPDATE_PRODUCT_VARIANT_CHOICES = 'UPDATE_PRODUCT_VARIANT_CHOICES'

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_CATEGORIES_FULFILLED = 'GET_ALL_CATEGORIES_FULFILLED'
export const GET_CATEGORY_FORM = 'GET_CATEGORY_FORM'
export const SUBMIT_CATEGORY_FORM = 'SUBMIT_CATEGORY_FORM'
export const UPDATE_CATEGORY_VISIBILITY = 'UPDATE_CATEGORY_VISIBILITY'
export const UPDATE_CATEGORY_VISIBILITY_PENDING = 'UPDATE_CATEGORY_VISIBILITY_PENDING'
export const UPDATE_CATEGORY_ORDERS = 'UPDATE_CATEGORY_ORDERS'
export const UPDATE_CATEGORY_ORDERS_PENDING = 'UPDATE_CATEGORY_ORDERS_PENDING'
export const UPDATE_CATEGORY_ORDERS_FULFILLED = 'UPDATE_CATEGORY_ORDERS_FULFILLED'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_PENDING = 'DELETE_CATEGORY_PENDING'
export const DELETE_CATEGORY_FULFILLED = 'DELETE_CATEGORY_FULFILLED'

export const GET_CATEGORIES_DROPDOWN = 'GET_CATEGORIES_DROPDOWN'

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_PRODUCT_FORM = 'GET_PRODUCT_FORM'
export const SUBMIT_PRODUCT_FORM = 'SUBMIT_PRODUCT_FORM'
export const UPDATE_PRODUCT_VISIBILITY = 'UPDATE_PRODUCT_VISIBILITY'
export const UPDATE_PRODUCT_VISIBILITY_PENDING = 'UPDATE_PRODUCT_VISIBILITY_PENDING'
export const COPY_PRODUCT = 'COPY_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_MULTIPLE_PRODUCTS = 'DELETE_MULTIPLE_PRODUCTS'
export const REMOVE_MULTIPLE_FROM_CATEGORY = 'REMOVE_MULTIPLE_FROM_CATEGORY'

export const GET_STOCK_HISTORY = 'GET_STOCK_HISTORY'
export const GET_PRODUCT_STOCK = 'GET_PRODUCT_STOCK'
export const GET_PRODUCT_STOCK_FORM = 'GET_PRODUCT_STOCK_FORM'
export const SUBMIT_PRODUCT_STOCK = 'SUBMIT_PRODUCT_STOCK'
export const SUBMIT_PRODUCT_STOCK_FULFILLED = 'SUBMIT_PRODUCT_STOCK_FULFILLED'
export const ENABLE_PRODUCT_STOCK = 'ENABLE_PRODUCT_STOCK'
export const SAVE_PRODUCT_VARIANTS = 'SAVE_PRODUCT_VARIANTS'
export const SAVE_PRODUCT_VARIANTS_FULFILLED = 'SAVE_PRODUCT_VARIANTS_FULFILLED'
export const GET_ALL_PRODUCT_VARIANTS = 'GET_ALL_PRODUCT_VARIANTS'
export const GET_ALL_GLOBAL_VARIANTS = 'GET_ALL_GLOBAL_VARIANTS'
export const SUBMIT_PRODUCT_FORM_FULFILLED = 'SUBMIT_PRODUCT_FORM_FULFILLED'
export const SUBMIT_PRODUCT_DNDSORT = 'SUBMIT_PRODUCT_DNDSORT'
export const SUBMIT_PRODUCT_DNDSORT_FULFILLED = 'SUBMIT_PRODUCT_DNDSORT_FULFILLED'
export const SUBMIT_PRODUCT_ADDTOCATEGORY = 'SUBMIT_PRODUCT_ADDTOCATEGORY'
export const SUBMIT_PRODUCT_ADDTOCATEGORY_FULFILLED = 'SUBMIT_PRODUCT_ADDTOCATEGORY_FULFILLED'
export const RESET_PRODUCT_DND = 'RESET_PRODUCT_DND'

export const UPDATE_INCLUDES_TAX = 'UPDATE_INCLUDES_TAX'
export const UPDATE_CATEGORY_COUNT = 'UPDATE_CATEGORY_COUNT'
export const GET_SIGNED_URL = 'GET_SIGNED_URL'
export const GET_SESSION_URL = 'GET_SESSION_URL'
