import React from 'react'
import PropTypes from 'prop-types'
import './lang'
import Text from 'localize'
import { makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import './style/setup-status-nav-link.scss'
import { withRouter } from 'react-router-dom'

import { getDataProperty, DataElementLocations, DataElementTypes } from '@eig-builder/core-utils/helpers/tagging-helper'

function SetupStatusNavLink (props) {
  const classes = useStyles()
  const completionPercentage = props.completionPercentage
  const completionDiff = 100 - completionPercentage
  const isSetupGuide = props.history.location.pathname === '/setup-guide'

  return (
    <div
      className={`setup-button ` + classes.setupButton}
      {...getDataProperty({
      dataElementLocation: DataElementLocations.NAV,
      dataElementLabel: 'setup-status-nav-link',
      dataElementId: `setup-status-nav-link-id`,
      dataElementType: DataElementTypes.MENUITEM
    })}>
      { (isSetupGuide)
      ? <div className={classes.chartAndText}>
        <div className={`chart ` + classes.completionChart}>
          <svg width='100%' height='100%' viewBox='0 0 40 40' className='donut'>
            <circle className={classes.donutRing} cx='20' cy='20' r='15.91549430918954' fill='transparent' stroke-width='3.5' stroke-dasharray='3' />
            <circle className={classes.donutSegment} cx='20' cy='20' r='15.91549430918954' fill='transparent' stroke-width='3.5' stroke-dasharray={completionPercentage + ' ' + completionDiff} stroke-dashoffset='25' />
          </svg>
          <CheckIcon className={classes.donutCheck} />
        </div>
        <div className={classes.textSpans}>
          <Text message='store.setupNavButton.titleHome' />
          <span className={classes.percentNum}> {completionPercentage + '%'} </span>
          <Text message='store.setupNavButton.titleHomeComplete' />
        </div>
      </div>
      : <>
        <Text message='store.setupNavButton.title' className={classes.textSpans} />
        <span className={classes.percentNum}> {completionPercentage + '%'}</span>
      </> }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  setupButton: {
    border: '2px solid ' + theme.palette.primary.main,
    borderRadius: '4px',
    color: theme.palette.white.main,
    background: theme.palette.primary.main,
    padding: '8px 16px'
  },
  textSpans: {
    whiteSpace: 'normal'
  },
  chartAndText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
  },
  completionChart: {
    position: 'relative',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    minHeight: '32px',
    fontSize: '16px',
    margin: '0 8px 0 0'
  },
  donutRing: {
    stroke: theme.palette.white.main
  },
  donutSegment: {
    transformOrigin: 'center',
    stroke: theme.palette.white.main
  },
  donutCheck: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  percentNum: {
    whiteSpace: 'pre'
  }
}))
SetupStatusNavLink.propTypes = {
  completionPercentage: PropTypes.number,
  history: PropTypes.object
}
export default withRouter(SetupStatusNavLink)
