import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  productImportRetrieving: false,

  productImportResponse: [],
  productImportError: null,
  productImportSuccess: false,

  openImportWindow: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.RESET_PRODUCT_IMPORT: {
      return {
        ...state,
        productImportResponse: [],
        productImportError: null,
        productImportRetrieving: false,
        productImportSuccess: false
      }
    }
    case constants.SET_OPEN_IMPORT_WINDOW: {
      return {
        ...state,
        openImportWindow: action.payload
      }
    }
    case constants.PRODUCT_IMPORT_FULFILLED: {
      return {
        ...state,
        productImportResponse: action.body,
        productImportRetrieving: false,
        productImportSuccess: true
      }
    }
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.PRODUCT_IMPORT) ||
    state
  )
}
export default reducer
