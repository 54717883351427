import {
  Localizations
} from 'localize'

Localizations.append({
  modules: {
    settings: {
      routes: {
        settings: {
          pageTitle: 'Settings'
        },
        taxDetails: {
          pageTitle: 'Tax details'
        },
        shippingSettings: {
          pageTitle: 'Shipping Settings'
        },
        notificationSettings: {
          pageTitle: 'Notification Settings'
        },
        taxSettings: {
          pageTitle: 'Tax Settings'
        },
        legalSettings: {
          pageTitle: 'Legal Settings'
        },
        trackingSettings: {
          pageTitle: 'Tracking Settings'
        },
        advancedSettings: {
          pageTitle: 'Advanced Settings'
        },
        editSettings: {
          pageTitle: 'Edit Settings'
        }
      },
      images: {
        empty: 'Click to upload images',
        logo: 'Logo'
      }
    }
  }
})
