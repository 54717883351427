const config = {
  devPort: 4001,

  // localStoreInstanceId: '9256f5de040d44449ae0e5ec7901816a',
  // localStoreInstanceId: '63a1e9da44f445dd804c427e57984c02',
  // localStoreInstanceId: 'c9e9262994bb4a35bfedc62bfe007cd5',
  // localStoreInstanceId: 'a0e184760eda4816949c97dc38e2555d',
  // localStoreInstanceId: '63a1e9da44f445dd804c427e57984c02',
  // localStoreInstanceId: 'f10cd538335348eba2761a2987340952', // wb
  // localStoreInstanceId: '4b2a7fe0c35340738e4db67d3010ffd7',
  // localStoreInstanceId: '9f592dca854f4e7da5d6ef15e98d396d',
  // localStoreInstanceId: '841f4a8e2bc74de7b99d387c0db80471', // shippo enabled
  // localStoreInstanceId: 'd6f365dbccff4da1a992ce38ab9e6b93',
  // localStoreInstanceId: 'a7222c9c6b244d51b569714fc7a9e343', // uat
  // localStoreInstanceId: '9a456194f29f4f8fa2a468741cde0725', // qa
  // localStoreInstanceId: 'bf07cf44c0ed4c9ba6f27f31a438c6c5', // qa sitebuilder
  // localStoreInstanceId: '024f90bf80c749bd8775ff3db219139b',
  // localStoreInstanceId: 'c00db8d60edf4dabb00b0bb0a679f4c0',    // ctct
  // localStoreInstanceId: '6155619c1bbf4102a6e5a4a1a54104f0',
  localStoreInstanceId: 'f10cd538335348eba2761a2987340952', // uat wb
  // localStoreInstanceId: '20b0bdf54f7b4cea9ffcb9c98743f644', // uat wb
  // localStoreInstanceId: '7e90efdbb75e4b62b7e906c48121cdac', // onboarding test store
  // localStoreInstanceId: 'cfe0d7ffbd8e44dc8275e82f2978d211',
  // localStoreInstanceId: 'pBC6HhlybUOK+0ZwyV/ApAUGAAAAWQMAADEwMDAwMDc0OTg', // uat
  // localStoreInstanceId: 'c5f4192e3b254bdf88e2ee79750e1de4', // uat
  // localStoreInstanceId: 'Q937oZ+gx06G4TuFekRL5gI0NTUyNzU3MA', // <-- storeId 1 in dev database
  // localStoreInstanceId: 'pBC6HhlybUOK+0ZwyV/ApAUGAAAAQQMAADEwMDAwMDc0NTU', // <-- qa store with shippo setup
  // localStoreInstanceId: '09371951e720468d8aaf3c5f3b5cd7ab', // <-- qa wb
  // localStoreInstanceId: '9f592dca854f4e7da5d6ef15e98d396d',
  // localStoreInstanceId: 'aba36857b2cf4bef9adfc72ce1374f1b',

  // sets the default theme from the /brands folder
  brand: 'websitebuilder',

  // set the environment, this will change the urls 'dev', 'prototype3'
  env: 'uat',
  // env: 'uat',

  // Logger for redux, defaults to false when in production
  reduxLogger: true,

  product: 'store',

  getCurrentBrand (env) {
    if (env && env.brand) {
      return env.brand
    }

    return this.brand
  },

  getCustomAlias () {
    return {
      authentication_alias: 'core/authentication-editor',
      image_field_alias: './../components/fields/sitebuilder-image',
      '@eig-builder/module-authentication': 'core/authentication-editor'
    }
  },
  getCustomRuntimeConfig (runtimeConfig) {
    return Object.assign({}, runtimeConfig, {
      _fetchXsrfTokenHeaderName: 'X-CSRF-TOKEN',
      _fetchXsrfTokenSiteName: 'app-gateway',
      _fetchXsrfTokenEndpoint: '/accounts/v1.0/session/preauth',
      _fetchTokenSiteName: 'control-panel',
      _enableAppGateway: true,
      _fetchXsrfTokenDisabled: true,
      _defaultApiName: 'store-edit'
    })
  }
}

module.exports = config
