export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_FULFILLED = 'GET_CURRENT_USER_FULFILLED'
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR'
export const GET_CURRENT_USER_PENDING = 'GET_CURRENT_USER_PENDING'
export const GET_XSRF_TOKEN = 'GET_XSRF_TOKEN'
export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN'
export const UPDATE_LIMITATION = 'UPDATE_LIMITATION'
export const GET_LIMITATIONS = 'GET_LIMITATIONS'
export const DISABLE_FEATURE_FLAG = 'DISABLE_FEATURE_FLAG'
export const ENABLE_FEATURE_FLAG = 'ENABLE_FEATURE_FLAG'
export const UPDATE_FEATURE_FLAG = 'UPDATE_FEATURE_FLAG'
export const GET_XSRF_TOKEN_FULFILLED = 'GET_XSRF_TOKEN_FULFILLED'
