import '../components/lang'
import indexOf from 'lodash/indexOf'
import find from 'lodash/find'

const headerPrefixPaymentMethod = 'modules.orders.orderPaymentMethod'
const paymentMethodMapping = [
  {
    id: 0,
    displayName: `${headerPrefixPaymentMethod}.unknown`
  },
  {
    id: 1,
    displayName: `${headerPrefixPaymentMethod}.test`
  },
  {
    id: 5,
    displayName: `${headerPrefixPaymentMethod}.paypal`
  },
  {
    id: 10,
    displayName: `${headerPrefixPaymentMethod}.stripe`
  },
  {
    id: 20,
    displayName: `${headerPrefixPaymentMethod}.mollie`
  },
  {
    id: 35,
    displayName: `${headerPrefixPaymentMethod}.payubiz`
  },
  {
    id: 40,
    displayName: `${headerPrefixPaymentMethod}.pagseguro`
  },
  {
    id: 50,
    displayName: `${headerPrefixPaymentMethod}.cash`
  },
  {
    id: 990,
    displayName: `${headerPrefixPaymentMethod}.free`
  },
  {
    id: 1000,
    displayName: `${headerPrefixPaymentMethod}.none`
  }
]
export function getOrderPaymentMethod () {
  return paymentMethodMapping
}

export function getPaymentMethodMapping (paymentMethodId) {
  const node = find(paymentMethodMapping, x => x.id === paymentMethodId)
  if (node) {
    return node.displayName
  } else {
    return ''
  }
}

const headerPrefixPaymentStatus = 'modules.orders.orderPaymentStatus'
const paymentStatusMapping = [
  {
    id: 0,
    displayName: `${headerPrefixPaymentStatus}.waiting`
  },
  {
    id: 10,
    displayName: `${headerPrefixPaymentStatus}.pending`
  },
  {
    id: 30,
    displayName: `${headerPrefixPaymentStatus}.completed`
  },
  {
    id: 60,
    displayName: `${headerPrefixPaymentStatus}.canceled`
  },
  {
    id: 70,
    displayName: `${headerPrefixPaymentStatus}.expired`
  },
  {
    id: 80,
    displayName: `${headerPrefixPaymentStatus}.refunded`
  },
  {
    id: 90,
    displayName: `${headerPrefixPaymentStatus}.failed`
  },
  {
    id: 91,
    displayName: `${headerPrefixPaymentStatus}.declined`
  },
  {
    id: 5,
    displayName: `${headerPrefixPaymentStatus}.waitingForProvider`
  }
]
export function getOrderPaymentStatus () {
  return paymentStatusMapping
}

export function getPaymentStatusMapping (paymentStatusId) {
  const node = find(paymentStatusMapping, x => x.id === paymentStatusId)
  if (node) {
    return node.displayName
  } else {
    return ''
  }
}

export function getOrderStatus () {
  const headerPrefix = 'modules.orders.orderStatus'
  return [
    {
      id: 2,
      displayName: `${headerPrefix}.inprogress`
    },
    {
      id: 3,
      displayName: `${headerPrefix}.completed`
    },
    {
      id: 4,
      displayName: `${headerPrefix}.canceled`
    }
  ]
}

export function countryIncludesStates (country) {
  return indexOf([
    'US',
    'BR',
    'CA',
    // getOrderFormResponse returns Full Country Name
    'United States of America',
    'Brazil',
    'Canada'
  ], country) > -1
}
