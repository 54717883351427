/* eslint-disable no-case-declarations */
import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  subdivisionData: null,
  mapKey: null,
  maps: {},
  data: {}
}

const determineMap = data => {
  const { countries = {} } = data
  const { us = {} } = countries
  const { value = 0 } = us

  const keys = Object.keys(countries).filter(key => key !== 'us')

  const total = keys.reduce((acc, cur) => { return acc + countries[cur].value }, 0) * 2

  return value > total ? 'us' : 'world'
}

const mapObject = (key, data) => ({ 'hc-key': key.split(/(?=[A-Z])/).join('-').toLowerCase().split('/').join('-'), ...data[key] })

const parseData = (data, mapKey) => {
  const { countries = {}, mostSpecificSubdivisions = {} } = data

  return mapKey === 'world'
    ? Object.keys(countries).map(key => mapObject(key, countries))
    : Object.keys(mostSpecificSubdivisions).filter(key => mostSpecificSubdivisions[key].parent === 'us').map(key => mapObject(key, mostSpecificSubdivisions))
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_INSIGHTS_DATA_FULFILLED:
      const { body = {} } = action
      const mapId = determineMap(body.users)

      return {
        ...state,
        mapKey: mapId,
        subdivisionData: parseData(body.users, mapId),
        data: body,
        getInsightsDataRetrieving: false
      }
    case constants.GET_INSIGHTS_MAP_FULFILLED:
      const { mapKey = null, maps = {} } = state

      if (!mapKey) {
        return state
      }

      if (!maps[mapKey]) {
        if (!action.body) {
          return state
        }

        return {
          ...state,
          maps: { ...maps, [mapKey]: action.body },
          getInsightsMapRetrieving: false
        }
      }

      return state
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_INSIGHTS_SSO_URL) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_INSIGHTS_DATA) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_INSIGHTS_MAP) ||
    state
  )
}

export default reducer
