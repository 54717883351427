import get from 'lodash/get'
import { globalShoppableReturnUrl } from '../core/globalShoppableReturnUrl'
import STORE_TYPES from '../modules/setup/store/enums/storeTypes'

export const getCheckListItems = (status, hasSite) => {
  const hasProducts = get(status, 'hasProducts', false)
  const hasContactInfo = get(status, 'hasContactInfo', false)
  const hasPaymentProvider = get(status, 'hasPaymentProvider', false)

  return [hasProducts, hasContactInfo, hasPaymentProvider, hasSite]
}

export const isItShoppable = status => {
  // determine if is shoppable
  const storeType = get(status, 'storeType', false)
  const shoppableReturnUrl = globalShoppableReturnUrl()

  return shoppableReturnUrl || storeType === STORE_TYPES.CTCT_SHOPPABLE_LANDING
}

export const getNumberOfStepsComplete = (checkListItems, isShoppable) => {
  let stepsComplete = 1 // shipping step is complete by default
  if (isShoppable) {
    checkListItems.pop() // site step is irrelevant
  }
  checkListItems.forEach(item => {
    if (item === true) { stepsComplete++ }
  })
  return stepsComplete
}
