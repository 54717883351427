import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { EDITOR } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [
  {
    path: '/product/onboarding',
    component: LazyLoadWithCircularProgress(() => import('./containers/product-onboarding')),
    pathToLeaveFocusView: '/products',
    hideNav: true,
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/product/detail/:id?',
    component: LazyLoadWithCircularProgress(() => import('./containers/product-detail')),
    pathToLeaveFocusView: '/products',
    pageType: EDITOR,
    flowType: STORE
  },
  {
    path: '/products',
    component: LazyLoadWithCircularProgress(() => import('./containers/base')),
    pageType: EDITOR,
    flowType: STORE
  }
]

export default Routes
