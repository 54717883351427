import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

// Uncomment the lines above when you start using the reducer

const initialState = {}

const reducer = (state = initialState, action) => {
  return ReducerHelper.listenToFetchActions(state, action, constants.GET_DEBUG_URL) || state
}

export default reducer
