import { Localizations } from 'localize'

Localizations.append({
  store: {
    setupNavButton: {
      title: 'Go to Setup Guide',
      titleHome: 'Setup',
      titleHomeComplete: 'Complete'
    }
  }
})
