import React, { useEffect, useState } from 'react'

import LeftNavWrapper from 'core/app-wrapper-with-left-navbar'
import HamburgerWrapper from 'core/app-wrapper-with-hamburger'
import MD from '@eig-builder/core-utils/helpers/mobile-detection-helper'

const WrapperWithStoreEditorMenuItems = props => {
  // Load Iframe with SDK and set listener on resize
  const getIsDesktopUp = () => {
    return document.documentElement.clientWidth > 992
  }
  const [isDesktopUp, setIsDesktopUp] = useState(getIsDesktopUp())
  const displayWindowSize = () => {
    setIsDesktopUp(getIsDesktopUp())
  }
  useEffect(() => {
    window.addEventListener('resize', displayWindowSize)
    window.top.postMessage({ type: 'ComponentLoaded' }, '*')
  }, [])

  if (isDesktopUp) {
    return <LeftNavWrapper breadCrumb={[]} drawerIsOpen={!MD()} {...props} />
  } else {
    return <HamburgerWrapper {...props} />
  }
}

export default WrapperWithStoreEditorMenuItems
