import { Localizations } from 'localize'

Localizations.append({
  modules: {
    tooltips: {
      orders: {
        arrangeProducts: 'Rearrange Products',
        activateDeactivate: 'Activate/Deactivate',
        togglevisibility: 'Toggle visibility',
        edit: 'Edit',
        delete: 'Delete',
        displayName: 'Please fill out this field',
        currency: 'Please match the requested format',
        rightview: 'Categories',
        rearrange: 'Rearrange Products',
        filter: 'Show/Hide Filters',
        search: 'Search',
        column: 'Manage Columns',
        export: 'Export data to Excel document',
        exportToCSV: 'Export data to CSV document'
      }
    },
    orders: {
      components: {
        list: {
          core: {
            title: 'Orders'
          },
          tabs: {
            inProgress: 'In progress',
            completed: 'Completed',
            canceled: 'Canceled',
            abandonedCart: 'Abandoned cart'
          },
          headers: {
            orderNumber: 'Invoice',
            orderNumberPrefix: 'Invoice Prefix',
            orderDate: 'Order Date',
            subtotal: 'SubTotal',
            total: 'Total',
            customerId: 'Customer',
            customerEmail: 'Customer Email',
            testOrder: 'Test Order',
            shipping: 'Shipping',
            transactionFee: 'Transaction Fee {0}',
            tax: 'Tax',
            currencyIsoCode: 'Currency',
            paymentMethod: 'Payment Provider',
            paymentStatus: 'Payment Status',
            status: 'Status',
            discountAmount: 'Discount Amount',
            discount: 'Discount',
            paymentDate: 'Payment Date',
            customerPhone: 'Phone',
            customerAddress1: 'Street Address',
            customerAddress2: 'Street Address (second line)',
            customerCity: 'City',
            customerState: 'State',
            customerCountry: 'Country',
            customerZip: 'Zip',
            deliveryAddress1: 'Delivery Address',
            deliveryAddress2: 'Delivery Address (second line)',
            deliveryCity: 'Delivery City',
            deliveryState: 'Delivery State',
            deliveryCountry: 'Delivery Country',
            deliveryZip: 'Delivery Zip',
            checkout: 'Checkout',
            date: 'Date',
            name: 'Name',
            emailAddress: 'Email Address',
            emailStatus: 'Email Status',
            amount: 'Amount',
            recoveryStatus: 'Recovery Status'
          },
          empty: {
            extraText: "Bummer... no sales yet. But the moment your first product is sold, we'll send you an email. At that point feel free to do your happy dance.... (we won't tell)",
            canceledOrdersText: 'You don\'t have any canceled orders',
            noInProgress: 'You don\'t have any in progress orders',
            noCompleted: 'You don\'t have any completed orders',
            noAbandonedRows: 'You don\'t have any abandoned carts',
            noRowsMessage: 'No {0} found'
          },
          upgrade: {
            message:
              'A {0} transaction fee is taken from every sale. You can upgrade to remove transaction fees from future orders.',
            upgradeButton: 'Upgrade'
          }
        },
        detail: {
          title2: 'Cart Items',
          titleOrder: 'Order',
          addOrder: 'order',
          itemSelected: ' Item selected',
          itemsSelected: ' Items selected',
          tabbar: {
            settings: 'Order',
            details: 'Customer details',
            history: 'History'
          },
          orders: {
            titles: {
              items: 'Your items',
              pickupItems: 'Pickup Items',
              shippingItems: 'Shipping Items',
              digital: 'Digital Downloads',
              otherMixedOrder: 'Other Items',
              other: 'Items',
              markAsShipped: 'Mark As Shipped',
              markAsComplete: 'Mark As Complete',
              sendReadyForPickupEmail: 'Send Ready For Pickup Email',
              resendReadyForPickupEmail: 'Resend Pickup Ready Email',
              deleteSelected: 'Delete',
              printShippingLabel: 'Print Address Label',
              shippingLabels: 'Shipping Labels',
              paymentStatus: 'Payment Status',
              shippingLabel: 'Create Shipping Label'
            },
            headers: {
              image: 'Image',
              description: 'Product',
              subscription: 'Subscription',
              label: 'Shipping',
              quantity: 'Quantity',
              price: 'Price',
              type: 'Type',
              deliveryAddress: 'Delivered to'
            },
            details: {
              subtotal: 'Subtotal',
              discount: 'Discount',
              tax: 'Taxes',
              shipping: 'Shipping',
              total: 'Total cost',
              free: 'Free',
              quantity: 'Quantity: ',
              printShippingLabel: 'Print Shipping Label',
              deleteShippingLabel: 'Delete label',
              cancelOrderLine: 'Cancel Item',
              createShippingLabel: 'Create Shipping Label',
              resetOrderLine: 'Reset',
              resendOrderLine: 'Resend Email'
            }
          },
          settings: {
            customer: 'Customer details',
            invoice: 'Payment details',
            delivery: 'Delivery details',
            notes: 'Notes'
          },
          addressLabel: {
            delivery: 'Delivery Address',
            return: 'Return Address',
            descriptionOne: 'Enter your package details and print your shipping label',
            nextButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            printLabel: 'Print label',
            confirm: 'Confirm shipping products',
            nameErrorText: 'This name field has a maximum length of 24 characters.',
            address1ErrorText: 'This address field has a maximum length of 48 characters.'
          },
          shippingLabel: {
            headers: {
              confirmItems: 'Confirm shipment products',
              confirmDetails: 'Confirm details',
              choosePackage: 'Choose Package Dimensions',
              purchaseLabel: 'Pay for your shipping'
            },
            description: {
              header: 'Give your customers the options',
              tagline: 'Variants let you customize each item with a set of attributes.',
              listItemOne: 'Sizes',
              listItemTwo: 'Colors',
              listItemThree: 'Weights',
              listItemFour: 'Packages',
              listItemFive: 'Any custom variable'
            },
            purchaseBtnText: 'Purchase',
            qty: 'Quantity:',
            weight: 'Weight:',
            wizard: {
              confirmDetails: {
                header: 'Confirm Customer Order Details',
                p1: 'The ship to address was entered by your customer during checkout.',
                p2: 'You only need to make changes on this page if your customer has notified you of a correction.'
              },
              chooseLabel: {
                header: 'Confirm Package Dimensions',
                p1:
                  'This package dimension is the default that you set up in shipping settings.  You can change it for this order if needed.  This change does not impact the shipping charge that your customer was billed.',
                p2:
                  'Please confirm that the weight is correct for the package. For future orders, update weight on the product detail page.'
              },
              purchaseLabel: {
                header: 'Confirm Shipping Speed',
                p1:
                  'Your customer has already selected and paid for the shipping option that is selected. If you are not going to make any changes to the shipment speed, simply click the Purchase button to confirm.',
                p2:
                  'You can choose to upgrade their shipping option, but you will be billed through Shippo, your customer’s shipping charge will not be impacted.'
              }
            },
            successDialog: {
              header: 'Your shipping label is ready to print',
              subtext1: 'You can reprint your label at any time',
              laterBtn: 'Do this later',
              printBtn: 'Print my label'
            }
          },
          resendFile: {
            header: 'Resend file',
            subtext: 'Your customer will receive another email giving them access to the download',
            customerEmail: 'Customer email',
            dialog: {
              cancelBtn: 'Cancel',
              confirmBtn: 'Send File'
            }
          },
          history: {
            headers: {
              date: 'Date',
              description: 'Description',
              orderStatus: 'Order Status'
            }
          },
          statusMessage: {
            readyForPickupEmailSent: 'Pickup status email is on its way to the customer.',
            pickupOrderIsComplete: 'Pickup order is complete.',
            invalidCustomerEmail:
              "It looks like your customer placed this order with an invalid email address. They haven't received an order confirmation."
          }
        },
        generic: {
          contactCustomer: 'Contact Customer',
          orderMessage: 'Order message',
          submit: 'Submit',
          cancel: 'Cancel',
          provideTracking:
            'Provide any additional information about the shipment (tracking id) that will be added to the email send to your customer',
          provideCancelled:
            'Provide any additional information about the cancelled order that will be added to the email send to your customer.'
        }
      },
      orderPaymentMethod: {
        unknown: ' ',
        test: 'Test',
        paypal: 'PayPal',
        stripe: 'Stripe',
        mollie: 'Mollie',
        payubiz: 'PayUBiz',
        pagseguro: 'PagSeguro',
        cash: 'Cash on Delivery',
        free: 'Free',
        none: 'None'
      },
      orderPaymentStatusSimplified: {
        waiting: 'Waiting',
        pending: 'Pending',
        completed: 'Completed',
        canceled: 'Canceled',
        expired: 'Expired',
        refunded: 'Refunded',
        failed: 'Failed',
        declined: 'Declined'
      },
      orderPaymentStatus: {
        waiting: 'Payment waiting',
        pending: 'Payment pending',
        completed: 'Payment completed',
        canceled: 'Payment canceled',
        expired: 'Payment expired',
        refunded: 'Payment refunded',
        failed: 'Payment failed',
        declined: 'Payment declined',
        waitingForProvider: 'Waiting for provider'
      },
      orderStatus: {
        inprogress: 'In Progress',
        completed: 'Completed',
        canceled: 'Canceled',
        readyForPickup: 'Ready for Pick Up'
      }
    }
  },
  components: {
    abandonedCartDetails: {
      grid: {
        subtotal: 'Subtotal: {0}',
        tooltip: 'Subtotal excludes shipping and taxes'
      }
    }
  }
})
