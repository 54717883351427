import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import {
  findCategory,
  removeCategory,
  addCategoryToList,
  getNestedChildren,
  setCorrectOrderNested,
  updateCategoryNodeTreeCount
} from '../helpers/category-helpers'

import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import forIn from 'lodash/forIn'

import './lang'

const initialState = {
  dndSort: [],
  dndAddProdToCat: [],
  dndAction: 0,
  dndLastState: {},

  getAllCategoriesRetrieving: false,
  getAllCategoriesResponse: [],

  getCategoryFormRetrieving: false,
  getCategoryFormResponse: null,

  submitCategoryFormResponse: null,
  submitCategoryFormRetrieving: false,

  deleteCategoryRetrieving: false,
  deleteCategoryResponse: null,

  updateCategoryVisibilityRetrieving: false,
  updateCategoryVisibilityResponse: null,

  getCategoriesDropdownRetrieving: false,
  getCategoriesDropdownResponse: [],

  getAllProductsRetrieving: false,
  getAllProductsResponse: [],

  getProductFormRetrieving: false,
  getProductFormResponse: null,

  submitProductFormResponse: null,
  submitProductFormRetrieving: false,

  deleteProductRetrieving: false,
  deleteProductResponse: null,

  deleteMultipleProductsRetrieving: false,
  deleteMultipleProductsResponse: null,

  getStockHistoryRetrieving: false,
  getStockHistoryError: false,
  getStockHistoryResponse: [],

  getProductStockRetrieving: false,
  getProductStockError: false,
  getProductStockResponse: {},

  getProductStockFormRetrieving: false,
  getProductStockFormError: false,
  getProductStockFormResponse: null,

  submitProductStockRetrieving: false,
  submitProductStockError: false,
  submitProductStockResponse: null,

  enableProductStockRetrieving: false,
  enableProductStockError: false,

  saveProductVariantsResponse: null,
  saveProductVariantsRetrieving: false,
  saveProductVariantsError: false,

  getAllProductVariantsRetrieving: false,
  getAllProductVariantsError: false,
  getAllProductVariantsResponse: null,

  getAllGlobalVariantsRetrieving: false,
  getAllGlobalVariantsResponse: null,
  getAllGlobalVariantsError: false,

  storeUpdateVariantChoices: [],

  includesTax: false,

  submitProductDndsortRetrieving: false
}

function getCategoriesObj (items, catId, returnObj) {
  for (const i in items) {
    if (items[i].id === catId) {
      this.item = {}
      this.item.categoryId = items[i].id
      this.item.displayName = items[i].displayName
      this.item.parentId = items[i].parentId
      this.item.order = null
      return
    } else if (items[i].children && items[i].children.length > 0) {
      getCategoriesObj.bind(this)(items[i].children, catId, returnObj)
    }
  }
}

function updateVisibility (resultSet, productId, visibility) {
  const item = find(resultSet, (o) => o.id === productId)
  if (item) {
    item.isVisible = visibility
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_PRODUCT_VARIANTS_FULFILLED: {
      const newState = { ...state }
      newState.saveProductVariantsRetrieving = false
      newState.saveProductVariantsResponse = action.body
      if (action.extraArguments.populateVariantFields) {
        newState.getAllProductVariantsResponse = action.body
        newState.storeUpdateVariantChoices = action.body.product_variant_choices
      }
      const find = !isEmpty(newState.getProductFormResponse) && newState.getAllProductsResponse.find(x => x.id === newState.getProductFormResponse.values.id)
      if (!isEmpty(find)) {
        find.hasProductVariantChoices = action.body.product_variants && action.body.product_variants.length > 0
      }
      return newState
    }
    case constants.SUBMIT_PRODUCT_FORM_FULFILLED: {
      const newState = { ...state }
      newState.submitProductFormResponse = action.body
      newState.submitProductFormRetrieving = false
      if (newState.getProductFormResponse) {
        newState.getProductFormResponse.values = action.extraArguments
      } else {
        newState.getProductFormResponse = {
          values: action.extraArguments
        }
      }
      return newState
    }
    case constants.UPDATE_PRODUCT_VARIANT_CHOICES: {
      return {
        ...state,
        storeUpdateVariantChoices: action.extraArguments.productVariantChoices
      }
    }
    case constants.RESET_GET_PRODUCT_FORM: {
      return {
        ...state,
        getProductFormRetrieving: false,
        getProductFormResponse: null,
        submitProductFormResponse: null,
        deleteProductResponse: null
      }
    }
    case constants.RESET_STOCK_FORM: {
      return {
        ...state,
        submitProductStockRetrieving: false,
        getProductStockFormResponse: null
      }
    }
    case constants.RESET_STOCK: {
      return {
        ...state,
        getProductStockRetrieving: false,
        getProductStockResponse: {},
        getStockHistoryRetrieving: false,
        getStockHistoryResponse: []
      }
    }
    case constants.RESET_PRODUCT_VARIANTS: {
      return {
        ...state,
        getAllProductVariantsRetrieving: false,
        getAllProductVariantsResponse: null,
        storeUpdateVariantChoices: []
      }
    }
    case constants.RESET_GET_CATEGORY_FORM: {
      return {
        ...state,
        getCategoryFormRetrieving: false,
        getCategoryFormResponse: null
      }
    }
    case constants.UPDATE_CATEGORY_ORDERS_FULFILLED:
    case constants.DELETE_CATEGORY_FULFILLED:
    case constants.GET_ALL_CATEGORIES_FULFILLED: {
      const newState = {
        ...state,
        getAllCategoriesRetrieving: false,
        removeMultipleFromCategoryResponse: null,
        removeMultipleFromCategoryRetrieving: false,
        deleteCategoryRetrieving: false
      }
      let arrTmp = []
      arrTmp = getNestedChildren(action.body, null)
      arrTmp = setCorrectOrderNested(arrTmp)
      newState.getAllCategoriesResponse = arrTmp
      return newState
    }
    case constants.DELETE_CATEGORY_PENDING: {
      const newState = {
        ...state,
        deleteCategoryRetrieving: true
      }
      removeCategory(newState.getAllCategoriesResponse, action.extraArguments.categoryId)
      newState.getAllCategoriesResponse = cloneDeep(newState.getAllCategoriesResponse)
      return newState
    }
    case constants.SUBMIT_PRODUCT_STOCK_FULFILLED: {
      const newState = {
        ...state,
        submitProductStockRetrieving: false,
        getProductStockFormResponse: null
      }
      newState.storeUpdateVariantChoices = cloneDeep(newState.storeUpdateVariantChoices)
      const productVariantChoice = newState.storeUpdateVariantChoices
        .find(item => item.id === newState.submitProductStockExtraArgs.product_variant_choice_id)
      if (productVariantChoice) {
        productVariantChoice.stock_value = Number(newState.submitProductStockExtraArgs.level) + Number(newState.submitProductStockExtraArgs.difference)
      } else {
        newState.getProductStockResponse = { level: Number(newState.submitProductStockExtraArgs.level) + Number(newState.submitProductStockExtraArgs.difference) }
      }
      return newState
    }
    case constants.UPDATE_CATEGORY_VISIBILITY_PENDING: {
      const newState = { ...state }
      const categoryItem = findCategory(newState.getAllCategoriesResponse, action.extraArguments.categoryId)
      if (categoryItem) {
        categoryItem.isVisible = action.extraArguments.visibility
      }
      newState.getAllCategoriesResponse = cloneDeep(newState.getAllCategoriesResponse)
      return newState
    }
    case constants.UPDATE_CATEGORY_ORDERS_PENDING: {
      const newState = { ...state }
      let changedParent
      action.extraArguments.categoryChanges.forEach((item, index) => {
        const categoryItem = findCategory(newState.getAllCategoriesResponse, item.id)
        if (categoryItem) {
          categoryItem.order = item.order
          if (categoryItem.parentId !== item.parent_id) {
            categoryItem.parentId = item.parent_id
            changedParent = categoryItem
            removeCategory(newState.getAllCategoriesResponse, categoryItem.id)
          }
        }
      })
      if (changedParent) {
        addCategoryToList(newState.getAllCategoriesResponse, changedParent)
      }
      newState.getAllCategoriesResponse = setCorrectOrderNested(newState.getAllCategoriesResponse)
      return newState
    }
    case constants.UPDATE_CATEGORY_COUNT: {
      const newState = cloneDeep(state)
      const catId = action.categoryId
      if (action.decreaseUncategorizeCount) {
        const uncategorizedNode = find(newState.getAllCategoriesResponse, (item) => item.id === 0)
        uncategorizedNode.productsInCategory -= 1
      }

      const productItem = action.productId &&
      find(newState.getAllProductsResponse, (item) => (item.id === action.productId))

      const productCategories = (productItem && productItem.productCategories) || []

      catId && updateCategoryNodeTreeCount(newState.getAllCategoriesResponse, catId, null, productCategories)
      return newState
    }
    case constants.UPDATE_PRODUCT_VISIBILITY_PENDING: {
      const newState = cloneDeep(state)
      updateVisibility(newState.getAllProductsResponse, action.extraArguments.productId, action.extraArguments.visibility)

      forIn(newState.dndLastState, (value, key) => {
        updateVisibility(value, action.extraArguments.productId, action.extraArguments.visibility)
      })
      return newState
    }
    case constants.SUBMIT_PRODUCT_DNDSORT_FULFILLED: {
      const newState = {
        ...state,
        submitProductDndsortRetrieving: false,
        dndSort: cloneDeep(state.dndSort)
      }

      newState.dndSort.push({
        productId: action.extraArguments.productId,
        fromIndex: action.extraArguments.fromIndex,
        toIndex: action.extraArguments.toIndex
      })

      if (action.extraArguments.catId || action.extraArguments.catId === 0) {
        newState.dndLastState[action.extraArguments.catId] = action.extraArguments.currState
      }
      return newState
    }

    case constants.SUBMIT_PRODUCT_ADDTOCATEGORY_FULFILLED: {
      const newState = { ...state }
      newState.dndAddProdToCat.push({ productId: action.extraArguments.productId, catId: action.extraArguments.categoryId })

      const catObj = {}
      getCategoriesObj.bind(catObj)(newState.getAllCategoriesResponse, action.extraArguments.categoryId)

      const productIndex = findIndex(newState.getAllProductsResponse, (item) => (item.id === action.extraArguments.productId))
      if (productIndex > -1) {
        newState.getAllProductsResponse = [...state.getAllProductsResponse]

        const { getAllProductsResponse } = newState
        getAllProductsResponse[productIndex] = cloneDeep(state.getAllProductsResponse[productIndex])
        Array.isArray(getAllProductsResponse[productIndex].productCategories) &&
        getAllProductsResponse[productIndex].productCategories.push(catObj.item)
      }

      if (newState.dndLastState[action.extraArguments.categoryId]) {
        newState.dndLastState[action.extraArguments.categoryId].push(newState.getAllProductsResponse[productIndex])
      }

      newState.dndAction += 1
      return newState
    }
    case constants.RESET_PRODUCT_DND: {
      const newState = { ...state }
      newState.dndSort = []
      newState.dndLastState = {}
      newState.dndAction = 0
      newState.dndAddProdToCat = []
      return newState
    }
  }

  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_CATEGORIES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CATEGORY_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_CATEGORY_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_CATEGORY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.UPDATE_CATEGORY_VISIBILITY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.UPDATE_CATEGORY_ORDERS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CATEGORIES_DROPDOWN) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_PRODUCTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_PRODUCT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_PRODUCT) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_MULTIPLE_PRODUCTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.REMOVE_MULTIPLE_FROM_CATEGORY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.UPDATE_PRODUCT_VISIBILITY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.COPY_PRODUCT) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_STOCK_HISTORY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_STOCK) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PRODUCT_STOCK_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_PRODUCT_STOCK) ||
    ReducerHelper.listenToFetchActions(state, action, constants.ENABLE_PRODUCT_STOCK) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_PRODUCT_VARIANTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_PRODUCT_VARIANTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_PRODUCT_DNDSORT) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_GLOBAL_VARIANTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.UPDATE_INCLUDES_TAX) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SIGNED_URL) ||
    state
  )
}

export default reducer
