import {
  Localizations
} from 'localize'

Localizations.append({
  store: {
    core: {
      floatingLeftNavBar: {
        headerTitle: 'Store',
        headerSubTitle: 'manage'
      }
    }
  }
})
