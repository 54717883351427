import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CircularProgress from '@eig-builder/core-ui/CircularProgress'

import isEmpty from 'lodash/isEmpty'

import * as actions from '../store/actions'

import './lang'

const mapStateToProps = (state) => {
  return {
    retrieving: state.auth && state.auth.retrieving,
    features: state.auth && state.auth.features
  }
}

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

/**
 * Authentication Container, making sure that a user is logged in, it will return the user to the redirect urls provided by the server then the user is not signed in
 *
 * @class AuthContainer
 * @extends {Component}
 * @param actions - object with all available actions
 * @param loginUrl - return login url if a user is not signed in
 * @param isAuthenticated - users signed in
 * @param children - this nodes will be diplayed when a user is signed in
 */
class AuthContainer extends Component {
  static propTypes = {
    actions: PropTypes.object,
    analytics: PropTypes.object,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    features: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.props.actions.checkAuth().then(({ payload }) => {
      if (this.props.analytics) {
        this.props.analytics.login(payload.instance_id, payload.email, payload.store_id, payload.global_identity)
      }
      this.props.actions.getLimitations()
    })
  }

  render () {
    if (isEmpty(this.props.features)) {
      return <div className='align-middle' style={{ textAlign: 'center', marginTop: '20%' }}><CircularProgress show centered /></div>
    }

    return this.props.children
  }
}

const auth = connect(mapStateToProps, mapDispatch)(AuthContainer)

export default auth
