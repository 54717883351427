import * as constants from './constants'

import { fetchAsJson } from '@eig-builder/core-utils/helpers/fetch-helper'
import { api } from '@eig-builder/core-utils/helpers/url-helper'

export const getSetupStatus = () => {
  return fetchAsJson(
    api('/v1.0/store/setup/status'),
    {
      key: constants.GET_STORE_STATUS,
      convertToCamelCase: true
    })
}
