import {
  Localizations
} from 'localize'

Localizations.append({
  modules: {
    store: {
      products: {
        pickupOnly: 'Pickup Only'
      }
    }
  }
})
