/* eslint-disable no-void */
/* eslint-disable no-case-declarations */
import * as constants from './constants'
import omit from 'lodash/omit'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

const initialState = {
  isAuthenticated: false,
  retrieving: true,
  customOBJ: true
}

const reducer = (state = initialState, action) => {
  const newState = cloneDeep(state)
  switch (action.type) {
    case constants.GET_CURRENT_USER_PENDING:
      const returnState = {
        ...newState,
        retrieving: true,
        loginUrl: void 0
      }
      delete returnState.retryTokenMeForRefresh
      return returnState
    case constants.GET_CURRENT_USER_FULFILLED:

      return {
        ...newState,
        retrieving: false,
        isAuthenticated: true,
        xsrfToken: action.response.headers.get('x-xsrf-token'),
        features: action.body.features,
        storeId: action.body.store_id,
        partnerId: action.body.partner_id,
        accountId: action.body.account_id,
        billingInfo: {
          code: get(action, 'body.currency')
        }
      }
    case constants.UPDATE_LIMITATION:
    // unlock this one feature, its a hack the limitation system takes to long to process orders
      set(newState.getLimitationsResponse, `limitations.${action.limitationKey}.value`, null)
      return {
        ...newState
      }
    case constants.GET_CURRENT_USER_ERROR:
      const xsrfToken = action.response && action.response.headers && action.response.headers.get('x-xsrf-token')

      return {
        ...newState,
        loginUrl: action.body && action.body.login_url,
        isAuthenticated: true, // todo remove then when freerk auth is working
        retrieving: false,
        xsrfToken: xsrfToken,
        hasXSRFError: !xsrfToken
      }

    case constants.GET_XSRF_TOKEN_FULFILLED:
      return {
        ...newState,
        hasXSRFError: false,
        xsrfToken: action.response.headers.get('x-xsrf-token')
      }

    case 'GET_STORE_SETTINGS_FULFILLED':
      return {
        ...newState,
        billingInfo: {
          code: get(action, 'body.currencySelector')
        }
      }

    case constants.DISABLE_FEATURE_FLAG:
      newState.features = omit(newState.features || {}, action.body.flag)
      return newState

    case constants.ENABLE_FEATURE_FLAG:
      newState.features = newState.features || {}
      newState.features[action.body.flag] = true
      return newState
  }

  return ReducerHelper.listenToFetchActions(state, action, constants.GET_LIMITATIONS) || state
}

export default reducer
