import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'

import useLimitation from 'core/useLimitation'

import MD from '@eig-builder/core-utils/helpers/mobile-detection-helper'
import { getCheckListItems, isItShoppable, getNumberOfStepsComplete } from '../../../../core/onboarding-percentage-helper'

import { NotificationBarContainer } from '@eig-builder/module-notification-bar-with-context'
import Modals from '@eig-builder/module-modals/containers/modal-root'

import FloatingLeftNavBar from './../../../floating-left-nav-bar'
import { DrawerContext } from './../../../contexts/drawer-context'
import SetupStatusNavLink from './../../../../modules/setup-guide/components/setup-status-nav-link'

import './lang'
import Text from 'localize'

import './../../style/wrapper.scss'

/**
 * Core that Wraps the application, will render the navbar and (left)sidebar
 *
 * @class Wrapper
 * @extends {Component}
 * @param drawerIsOpen - drawer is open boolean
 * @param breadCrumb - array of the current breadCrumb
 * @param menuItems - main menuItems that should be displayed
 * @param children - children that will be display in the content section
 */

const Wrapper = React.memo(function (props) {
  const [drawerIsOpen, setDrawerIsOpen] = useState(props.drawerIsOpen)
  const [toggleDrawer] = useState({ drawerIsOpen: !props.drawerIsOpen })
  const { status, getSetupStatus, getStoreStatusRetrieving } = props
  const [limitation] = useLimitation()

  useEffect(() => {
    getSetupStatus()
  }, [])

  const hasOnBoardingTime = get(status, 'onboardingTime', false)
  const hasSite = !!get(limitation, 'data.site_id')
  const isShoppable = isItShoppable(status)

  const checkListItems = getCheckListItems(status, hasSite)
  const stepsComplete = getNumberOfStepsComplete(checkListItems, isShoppable)
  /* initialize completion percentage to avoid
   * undefined state for completion percentage */
  let completionPercentage = (stepsComplete / (checkListItems.length + 1)).toFixed(2) * 100

  if (drawerIsOpen === !!props.hideNav) {
    setDrawerIsOpen(!props.hideNav)
  }

  const drawerIsVisible = () => {
    return drawerIsOpen // && !drawerDisabled
    /* drawerDisabled was never set in state in original logic
    and hence did not transfer to equivalent functional component. */
  }

  const stateClasses = () => {
    return classNames('store-core-components-app-wrapper', {
      'drawer-open': drawerIsVisible(),
      desktop: MD.mdUp,
      mobile: !MD.mdUp
    })
  }

  const getMenuItems = () => {
    // const showFacebookMenu = RuntimeConfigHelper.getRuntimeConfig()._env !== 'prod'

    const hasSite = !!get(props.getLimitationsResponse, 'site_id')

    const menuItems = []

    if (!hasOnBoardingTime) {
      if (!getStoreStatusRetrieving) {
        completionPercentage = (stepsComplete / (checkListItems.length + 1)).toFixed(2) * 100
      }

      menuItems.push({
        title: <SetupStatusNavLink completionPercentage={completionPercentage} />,
        link: '/setup-guide',
        tag: 'setup',
        class: 'setup-progress-button'
      })
    }

    if (hasSite) {
      menuItems.push({
        title: (<Text message='appStoreEditor.client.components.wrapper.home' />),
        link: '/insights',
        tag: 'home'
      })
    }

    if (props.showOrders) {
      menuItems.push({
        title: (<Text message='appStoreEditor.client.components.wrapper.orders' />),
        link: '/orders',
        tag: 'orders'
      })
    }

    menuItems.push({
      title: (<Text message='appStoreEditor.client.components.wrapper.products' />),
      link: '/products',
      tag: 'products'
    })

    menuItems.push({
      title: (<Text message='appStoreEditor.client.components.wrapper.settings' />),
      link: '/settings',
      tag: 'settings',
      activeOnlyWhenExact: false
    })

    if (props.showDiscounts) {
      menuItems.push({
        title: (<Text message='appStoreEditor.client.components.wrapper.discount' />),
        link: '/discounts',
        tag: 'discounts'
      })
    }

    if (props.showGlobalVariants) {
      menuItems.push({
        title: (<Text message='appStoreEditor.client.components.wrapper.globalVariants' />),
        link: '/global-variants',
        tag: 'global-variants'
      })
    }

    menuItems.push({
      title: (<Text message='appStoreEditor.client.components.wrapper.advancedFeature' />),
      link: '/features',
      tag: 'advanced-features',
      activeOnlyWhenExact: false
    })

    // {
    //   title: (<Text message='appStoreEditor.client.components.wrapper.facebookStore' />),
    //   link: '/facebook',
    //   materialIconTag: 'people',
    //   tag: 'facebookStore',
    //   visible: showFacebookMenu
    // }

    return menuItems
  }

  const { activePage, getLimitationsResponse } = props
  if (!getLimitationsResponse) {
    return null
  }

  return (
    <div className={stateClasses()}>
      <DrawerContext.Provider value={{ open: drawerIsOpen, toggle: toggleDrawer }}>
        {props.isAuthenticated
      ? (
        <div className='store-app-wrapper-body'>
          <div className='store-app-wrapper-childs'>
            <NotificationBarContainer targetKey='fullscreen' />
            {drawerIsVisible() && activePage && <FloatingLeftNavBar
              menuItems={getMenuItems()}
              />}
            <div className='store-app-wrapper-content'>
              {props.children}
            </div>
            <Modals />
          </div>
        </div>
      )
      : (
        <div className='store-app-wrapper-body'>
          <div className='store-app-wrapper-childs'>
            <NotificationBarContainer targetKey='fullscreen' />
            {props.children}
          </div>
        </div>
      )}
        <div id='customModalRoot' />
      </DrawerContext.Provider>
    </div>
  )
})

Wrapper.propTypes = {
  drawerIsOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isAuthenticated: PropTypes.bool,
  showOrders: PropTypes.bool,
  showDiscounts: PropTypes.bool,
  showGlobalVariants: PropTypes.bool,
  activePage: PropTypes.object,
  getLimitationsResponse: PropTypes.object,
  hideNav: PropTypes.bool,
  status: PropTypes.object,
  getSetupStatus: PropTypes.func,
  getStoreStatusRetrieving: PropTypes.bool
}

export default Wrapper
