import * as constants from './constants'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  getAllDiscountsRetrieving: false,
  getAllDiscountsResponse: [],

  getDiscountTypesRetrieving: false,
  getDiscountTypesResponse: [],

  getDiscountFormRetrieving: false,
  getDiscountFormResponse: null,

  submitDiscountFormResponse: null,
  submitDiscountFormRetrieving: false,

  deleteDiscountRetrieving: false,
  deleteDiscountResponse: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.RESET_GET_DISCOUNT_FORM: {
      return {
        ...state,
        getDiscountFormRetrieving: false,
        getDiscountFormResponse: null,
        submitDiscountFormExtraArgs: null,
        submitDiscountFormError: {}
      }
    }
    case constants.UPDATE_DISCOUNT_ACTIVITY_PENDING: {
      const newState = { ...state }
      const discountItem = find(newState.getAllDiscountsResponse, (item) => item.id === action.extraArguments.discountId)
      if (discountItem) {
        discountItem.isActive = action.extraArguments.activity
      }
      newState.getAllDiscountsResponse = cloneDeep(newState.getAllDiscountsResponse)
      return newState
    }
  }
  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ALL_DISCOUNTS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_DISCOUNT_TYPES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_DISCOUNT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SUBMIT_DISCOUNT_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.UPDATE_DISCOUNT_ACTIVITY) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_DISCOUNT) ||
    state
  )
}

export default reducer
