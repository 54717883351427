import { LazyLoadWithCircularProgress } from '../../core/lazyLoad'

import { CREATION } from '@eig-builder/core-utils/analytics/page-types'
import { STORE } from '@eig-builder/core-utils/analytics/flow-types'

const Routes = [{
  path: '/setup/overview',
  component: LazyLoadWithCircularProgress(() => import('./containers/overview')),
  pageTitle: 'Setting up you store:',
  exactly: true,
  pageType: CREATION,
  flowType: STORE
}]

export default Routes
