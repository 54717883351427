import * as constants from './constants'

import { snakeToCamelCaseObject } from '@eig-builder/core-utils/helpers/object-helper'

import * as ReducerHelper from '@eig-builder/core-utils/helpers/reducer-helper'

import isEmpty from 'lodash/isEmpty'

const initialState = {
  getStoreSettingsRetrieving: false,
  getStoreSettingsResponse: {},

  getShippingSettingsRetrieving: true,
  getShippingSettingsResponse: {},

  getLocalizationCountriesRetrieving: false,
  getLocalizationCountriesError: null,
  getLocalizationCountriesResponse: {},

  getLocalizationCountryStatesRetrieving: false,
  getLocalizationCountryStatesError: null,
  getLocalizationCountryStatesResponse: {},

  getLocalizationCurrenciesRetrieving: false,
  getLocalizationCurrenciesError: null,
  getLocalizationCurrenciesResponse: {},

  getBusinessFormRetrieving: false,
  getBusinessFormError: null,
  getBusinessFormResponse: {},

  saveBusinessFormRetrieving: false,
  saveBusinessFormError: null,
  saveBusinessFormResponse: null,

  getMetricsFormRetrieving: false,
  getMetricsFormError: null,
  getMetricsFormResponse: {},

  saveMetricsFormRetrieving: false,
  saveMetricsFormError: null,
  saveMetricsFormResponse: null,

  getShippingFormRetrieving: false,
  getShippingFormError: null,
  getShippingFormResponse: {},

  saveShippingFormRetrieving: false,
  saveShippingFormError: null,
  saveShippingFormResponse: null,

  getChargePerProductShippingFormRetrieving: false,
  getChargePerProductShippingFormError: null,
  getChargePerProductShippingFormResponse: {},

  saveChargePerProductShippingFormRetrieving: false,
  saveChargePerProductShippingFormError: null,
  saveChargePerProductShippingFormResponse: null,

  getLegalFormRetrieving: false,
  getLegalFormError: null,
  getLegalFormResponse: {},

  saveLegalFormRetrieving: false,
  saveLegalFormError: null,
  saveLegalFormResponse: null,

  getTaxFormRetrieving: false,
  getTaxFormError: null,
  getTaxFormResponse: {},

  saveTaxFormRetrieving: false,
  saveTaxFormError: null,
  saveTaxFormResponse: null,

  getTaxRuleTypesRetrieving: true,
  getTaxRuleTypesError: null,
  getTaxRuleTypesResponse: [],

  getTaxCountryManualRetrieving: true,
  getTaxCountryManualError: null,
  getTaxCountryManualResponse: [],

  getTaxCountryFormRetrieving: false,
  getTaxCountryFormError: null,
  getTaxCountryFormResponse: {},

  saveTaxCountryFormRetrieving: false,
  saveTaxCountryFormError: null,
  saveTaxCountryFormResponse: null,

  deleteTaxCountryFormRetrieving: false,
  deleteTaxCountryFormError: null,
  deleteTaxCountryFormResponse: {},

  getTaxRegionsFormRetrieving: false,
  getTaxRegionsFormError: null,
  getTaxRegionsFormResponse: {},

  saveTaxRegionsFormRetrieving: false,
  saveTaxRegionsFormError: null,
  saveTaxRegionsFormResponse: null,

  getNotificationPreviewRetrieving: false,
  getNotificationPreviewError: null,
  getNotificationPreviewResponse: {},

  getNotificationFormRetrieving: false,
  getNotificationFormError: null,
  getNotificationFormResponse: {},

  saveNotificationFormRetrieving: false,
  saveNotificationFormError: null,
  saveNotificationFormResponse: null,

  getCorreiosFormRetrieving: false,
  getCorreiosFormError: null,
  getCorreiosFormResponse: {},

  saveCorreiosFormRetrieving: false,
  saveCorreiosFormError: null,
  saveCorreiosFormResponse: null,

  disableCorreiosFormRetrieving: false,
  disableCorreiosFormError: null,
  disableCorreiosFormResponse: null,

  getGoShippoFormRetrieving: false,
  getGoShippoFormError: null,
  getGoShippoFormResponse: null,

  saveGoShippoFormRetrieving: false,
  saveGoShippoFormError: null,
  saveGoShippoFormResponse: null,

  getTrackingFormRetrieving: false,
  getTrackingFormError: null,
  getTrackingFormResponse: {},

  saveTrackingFormRetrieving: false,
  saveTrackingFormError: null,
  saveTrackingFormResponse: null,

  getPaymentOptionsOverviewRetrieving: false,
  getPaymentOptionsOverviewError: null,
  getPaymentOptionsOverviewResponse: null,

  getPaymentOptionsRetrieving: false,
  getPaymentOptionsError: null,
  getPaymentOptionsResponse: null,

  postSettingsOnboardingCompleteRetrieving: false,
  postSettingsOnboardingCompleteError: null,
  postSettingsOnboardingCompleteResponse: null,

  // REFACTOR - BIG use ready only property editor for overview pages
  shippingCountry: '',
  businessCountry: '',
  currency: '',

  getPickupFormRetrieving: false,
  getPickupFormResponse: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LOCALIZATION_COUNTRIES_FULFILLED: {
      const newState = { ...state }
      const { getStoreSettingsResponse } = newState

      newState.getLocalizationCountriesRetrieving = false
      newState.getLocalizationCountriesResponse = action.body

      if (!isEmpty(getStoreSettingsResponse) && !isEmpty(action.body)) {
        newState.shippingCountry = action.body[getStoreSettingsResponse.shippingCountry.toLowerCase()]
        newState.businessCountry = action.body[getStoreSettingsResponse.businessCountry.toLowerCase()]
      }

      return newState
    }
    case constants.GET_LOCALIZATION_CURRENCIES_FULFILLED: {
      return {
        ...state,
        getLocalizationCurrenciesRetrieving: false,
        getLocalizationCurrenciesResponse: action.body
      }
    }
    case constants.RESET_GET_TAX_COUNTRY_FORM: {
      return {
        ...state,
        getTaxCountryFormRetrieving: false,
        getTaxCountryFormResponse: null
      }
    }
    case constants.CHANGE_DEFAULT_SIZE: {
      const setValues = { ...state.getGoShippoFormResponse }
      setValues.values.height = action.extraArguments.value.height
      setValues.values.width = action.extraArguments.value.width
      setValues.values.length = action.extraArguments.value.length
      return {
        ...state,
        getGoShippoFormResponse: setValues
      }
    }
    case constants.RESET_GET_GOSHIPPO_FORM: {
      return {
        ...state,
        getGoShippoFormResponse: null,
        saveGoShippoFormResponse: null
      }
    }
    case constants.DISABLE_CORREIOS_FULFILLED: {
      const setSettings = { ...state.getShippingSettingsResponse }
      setSettings.isCorreiosEnabled = false
      return {
        ...state,
        getShippingSettingsResponse: setSettings
      }
    }
  }

  return (
    ReducerHelper.listenToFetchActions(state, action, constants.GET_STORE_SETTINGS, {
      response: function (body, newState, action) {
        const { getLocalizationCountriesResponse, getLocalizationCurrenciesResponse } = newState
        const { shippingCountry, businessCountry, currencySelector } = action.body || {}
        if (!isEmpty(getLocalizationCountriesResponse)) {
          newState.shippingCountry = getLocalizationCountriesResponse[shippingCountry.toLowerCase()]
          newState.businessCountry = getLocalizationCountriesResponse[businessCountry.toLowerCase()]
        }

        if (!isEmpty(getLocalizationCurrenciesResponse)) {
          newState.currency = getLocalizationCurrenciesResponse[currencySelector.toLowerCase()]
        }
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SHIPPING_SETTINGS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_LOCALIZATION_COUNTRIES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_LOCALIZATION_COUNTRY_STATES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_LOCALIZATION_CURRENCIES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_BUSINESS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_BUSINESS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_METRICS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_METRICS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SHIPPING_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_SHIPPING_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_LEGAL_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_LEGAL_FORM, {
      response: function (body, newState, action) {
        newState.getStoreSettingsResponse = {
          ...newState.getStoreSettingsResponse,
          ...snakeToCamelCaseObject(action.extraArguments)
        }

        // dont change the result
        return body
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TAX_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_TAX_FORM, {
      response: function (body, newState, action) {
        newState.getStoreSettingsResponse = {
          ...newState.getStoreSettingsResponse,
          ...snakeToCamelCaseObject(action.extraArguments)
        }

        // dont change the result
        return body
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TAX_COUNTRY_MANUAL) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TAX_RULE_TYPES) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TAX_COUNTRY_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_TAX_COUNTRY_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DELETE_TAX_COUNTRY_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TAX_REGIONS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_TAX_REGIONS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_NOTIFICATION_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_NOTIFICATION_PREVIEW) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_NOTIFICATION_FORM, {
      response: function (body, newState, action) {
        newState.getStoreSettingsResponse = {
          ...newState.getStoreSettingsResponse,
          ...snakeToCamelCaseObject(action.extraArguments)
        }
        return {
          ...body,
          resetToDefault: action.extraArguments.header_section === null && action.extraArguments.footer_section === null
        }
      }
    }) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SHIPPING_ADDRESS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_SHIPPING_ADDRESS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_CORREIOS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_CORREIOS_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.DISABLE_CORREIOS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_GO_SHIPPO_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_GO_SHIPPO_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_TRACKING_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_TRACKING_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PICKUP_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_SINGLE_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_SINGLE_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PAYMENT_OPTIONS_OVERVIEW) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_PAYMENT_OPTIONS) ||
    ReducerHelper.listenToFetchActions(state, action, constants.POST_SETTINGS_ONBOARDING_COMPLETE) ||
    ReducerHelper.listenToFetchActions(state, action, constants.GET_ONBOARDING_FORM) ||
    ReducerHelper.listenToFetchActions(state, action, constants.SAVE_ONBOARDING_FORM) ||
    state
  )
}

export default reducer
