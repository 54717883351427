import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import get from 'lodash/get'

import { NotificationBarContainer } from '@eig-builder/module-notification-bar-with-context'
import Modals from '@eig-builder/module-modals/containers/modal-root'

import HamburgerMenu from './../../../hamburger-menu'
import { DrawerContext } from './../../../contexts/drawer-context'
import SetupStatusNavLink from './../../../../modules/setup-guide/components/setup-status-nav-link'

import { getCheckListItems, isItShoppable, getNumberOfStepsComplete } from '../../../../core/onboarding-percentage-helper'

import './lang'
import Text from 'localize'

import './../../style/wrapper.scss'

/**
 * Core that Wraps the application, will render the navbar and (left)sidebar
 *
 * @class Wrapper
 * @extends {Component}
 * @param drawerIsOpen - drawer is open boolean
 * @param breadCrumb - array of the current breadCrumb
 * @param menuItems - main menuItems that should be displayed
 * @param children - children that will be display in the content section
 */
class Wrapper extends Component {
  static propTypes = {
    drawerIsOpen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isAuthenticated: PropTypes.bool,
    showOrders: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showGlobalVariants: PropTypes.bool,
    activePage: PropTypes.object,
    getLimitationsResponse: PropTypes.object,
    status: PropTypes.object,
    getSetupStatus: PropTypes.func,
    getStoreStatusRetrieving: PropTypes.bool
  }

  state = {
    drawerIsOpen: this.props.drawerIsOpen,
    toggleDrawer: () => {
      this.setState({ drawerIsOpen: !this.props.drawerIsOpen })
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.drawerIsOpen === !!props.hideNav) {
      return {
        drawerIsOpen: !props.hideNav
      }
    }
    return null
  }

  componentDidMount () {
    window.top.postMessage({ type: 'ComponentLoaded' }, '*')
    this.props.getSetupStatus()
  }

  getMenuItems = () => {
    // const showFacebookMenu = RuntimeConfigHelper.getRuntimeConfig()._env !== 'prod'

    const hasSite = !!get(this.props.getLimitationsResponse, 'site_id')
    const hasOnBoardingTime = get(this.props.status, 'onboardingTime', false)

    const checkListItems = getCheckListItems(this.props.status, hasSite)
    const isShoppable = isItShoppable(this.props.status)
    const stepsComplete = getNumberOfStepsComplete(checkListItems, isShoppable)
    /* initialize completion percentage to avoid
   * undefined state for completion percentage */
    let completionPercentage = (stepsComplete / (checkListItems.length + 1)).toFixed(2) * 100

    const menuItems = []

    if (!hasOnBoardingTime) {
      if (!this.props.getStoreStatusRetrieving) {
        completionPercentage = (stepsComplete / (checkListItems.length + 1)).toFixed(2) * 100
      }

      menuItems.push({
        title: <SetupStatusNavLink completionPercentage={completionPercentage} />,
        link: '/setup-guide',
        tag: 'setup',
        materialIconTag: 'no-icon',
        classes: 'setup-progress-button'
      })
    }

    if (hasSite) {
      menuItems.push({
        title: <Text message='appStoreEditor.client.components.wrapper.home' />,
        link: '/insights',
        tag: 'home',
        materialIconTag: 'store'
      })
    }

    if (this.props.showOrders) {
      menuItems.push({
        title: <Text message='appStoreEditor.client.components.wrapper.orders' />,
        link: '/orders',
        tag: 'orders',
        materialIconTag: 'home'
      })
    }

    menuItems.push({
      title: <Text message='appStoreEditor.client.components.wrapper.products' />,
      link: '/products',
      tag: 'products',
      materialIconTag: 'shopping_cart'
    })

    menuItems.push({
      title: <Text message='appStoreEditor.client.components.wrapper.settings' />,
      link: '/settings',
      tag: 'settings',
      activeOnlyWhenExact: false,
      materialIconTag: 'settings'
    })

    if (this.props.showDiscounts) {
      menuItems.push({
        title: <Text message='appStoreEditor.client.components.wrapper.discount' />,
        link: '/discounts',
        tag: 'discounts',
        materialIconTag: 'local_offer'
      })
    }

    if (this.props.showGlobalVariants) {
      menuItems.push({
        title: <Text message='appStoreEditor.client.components.wrapper.globalVariants' />,
        link: '/global-variants',
        tag: 'global-variants',
        materialIconTag: 'dns'
      })
    }

    menuItems.push({
      title: <Text message='appStoreEditor.client.components.wrapper.advancedFeature' />,
      link: '/features',
      tag: 'advanced-features',
      activeOnlyWhenExact: false,
      materialIconTag: 'toggle_on'
    })

    // {
    //   title: (<Text message='appStoreEditor.client.components.wrapper.facebookStore' />),
    //   link: '/facebook',
    //   materialIconTag: 'people',
    //   tag: 'facebookStore',
    //   visible: showFacebookMenu
    // }

    return [{ subItems: menuItems }]
  }

  /**
   * State class, will change on when the drawer is open, or the navbar is hidden
   *
   *
   * @memberOf Wrapper
   */
  stateClasses = () => {
    return classNames('store-core-components-app-wrapper', {})
  }

  render () {
    const { activePage, getLimitationsResponse } = this.props
    if (!getLimitationsResponse) {
      return null
    }
    return (
      <div className={this.stateClasses()}>
        <DrawerContext.Provider value={{ open: false }}>
          {this.props.isAuthenticated ? (
            <div>
              <NotificationBarContainer targetKey='wizard' />
              {activePage && <HamburgerMenu menuItems={this.getMenuItems()} />}
              <div className='store-app-wrapper-content'>{this.props.children}</div>
              <Modals />
            </div>
          ) : (
            <div>
              <div className='store-app-wrapper-childs'>
                <NotificationBarContainer targetKey='fullscreen' />
                {this.props.children}
              </div>
            </div>
          )}
          <div id='customModalRoot' />
        </DrawerContext.Provider>
      </div>
    )
  }
}

export default Wrapper
